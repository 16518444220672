import React, { createContext, useContext, useEffect, useState } from "react";

//
import { useLoadFilters } from "./QueryService";

// used hooks
import { useLocation } from "react-router-dom";
import { useSettings } from "./SettingsContext";

// Context for accessing Job Filters ("Attributes") of the current projects from anywhere in the app (see React Context)
// Job attributes are typically loaded once from the backend and then cached in the context.
const FiltersContext = createContext(undefined);

// Hook to provide access to context object.
const useFilters = () => {
  return useContext(FiltersContext);
};

/**
 * Returns data from the backend API for the job filters ("attributes ")
 */
const FiltersContextProvider = (props) => {
  const { settings } = useSettings();
  //const lang = new URLSearchParams(useLocation().search).get("lang") || "de";
  //Get Url parameter for language
  let getUrlParameter = function getUrlParameter(sParam) {
    let sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
    return false;
  };

  let lang = getUrlParameter('lang') || "de";
  // This will use the custom hook to load the filters from the backend
  const { isLoading, isError, data } = useLoadFilters(settings.baseUrl, lang);

  // Local  state for the filters
  const [filters, setFilters] = useState({ isLoading, isError, data });

  // Load available filter options (usually only once)
  useEffect(() => {
    // Load the filters from the backend
    setFilters({ isLoading, isError, data });
  }, [data]);

  return (
      <FiltersContext.Provider value={filters}>
        {props.children}
      </FiltersContext.Provider>
  );
};

export { FiltersContextProvider, useFilters };