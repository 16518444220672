import React, { useState, useEffect } from "react";
import { useSettings } from "../util/SettingsContext";
import { useFiltersParams } from "../util/FiltersParamsContext";
import { useFilters } from "../util/FiltersContext";

/**
 * Import your components here
 */
import { Container } from "@mui/material";
import JobAboForm from "./form";
import Header from "../components/Header";

/**
 * Main Layout for JobAbo
 */
const JobAboLayout = ({}) => {
  const { settings, setSettings } = useSettings();
  const { data: filtersData } = useFilters();

  // This is what we pass to child components to update the filtersParams
  const { filtersParams, setFiltersParams } = useFiltersParams();

  const [brand, setbrand] = useState("");

  useEffect(() => {
    const currentSearchParams = new URLSearchParams(window.location.search);
    const themeParam = currentSearchParams.get("theme");

    if (themeParam === "marche") {
      console.log("SETTING MARCHE CLASS");
      setbrand("marche");
    } else if (themeParam === "idms") {
      console.log("SETTING Interdiscount / Microspot CLASS");
      setbrand("idms");
    } else if (themeParam === "fust") {
      setbrand("fust");
    } else {
      setbrand("coop");
    }
  }, []);

  /**
   * Return or Career center main page
   */
  return (
    <>
      {brand && (
        <Container className={brand}>
          <Header brand={brand} filtersParams={filtersParams} setFiltersParams={setFiltersParams} />

          <div id="content-wrapper" className="abo">
            <JobAboForm
              attributes={filtersData.attributes}
              filtersParams={filtersParams}
              setFiltersParams={setFiltersParams}
            />
          </div>
          <div className="spacer"></div>
        </Container>
      )}
    </>
  );
};

export default JobAboLayout;
