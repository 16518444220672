import { TextField, Checkbox, Box, Grid } from "@mui/material";
import React from "react";

import AttributeFilterAutocomplete from "../components/AttributeFilterAutocomplete";
import JobAboSubscriptionForm from "../components/JobAboSubscriptionForm";
import { i18n } from "../util/i18n";
import { useSettings } from "../util/SettingsContext";
import TextSearchFilterAbo from "../components/TextSearchFilterAbo";
import WorkloadFilterDropdown from "../components/WorkloadFilterDropdown";
import { BsCheckSquare } from "react-icons/bs";
import { BsSquare } from "react-icons/bs";

/**
 *
 * @param {*} param0
 * @returns
 */
const JobAboForm = ({ attributes, filtersParams, setFiltersParams }) => {
  const { settings } = useSettings();
  const FILTER = settings.filterIds;

  const inputStyle = {
    "& .MuiFilledInput-root": {
      borderRadius: "8px",
      py: { xs: "3px", sm: "9px" },
      mt: { xs: "-3px", sm: "-9px" },
      pl: { xs: "10px", sm: "20px" },

      "& fieldset": {
        borderRadius: "8px",
      },
    },
  };

  return (
    <JobAboSubscriptionForm
      attributes={attributes}
      filtersParams={filtersParams}
      setFilterParams={setFiltersParams}
      className="jobabo-subscription-form"
    >
      <h1>{i18n.translations.jobaboSubscriptionFormTitle}</h1>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="abo-intro">
            <p>{i18n.translations.jobaboSubscriptionFormText}</p>
          </div>
          <h4>{i18n.translations.aboInfoLabel}</h4>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="jobabo_bezeichnung"
            name="jobabo_bezeichnung"
            label={i18n.translations.jobaboNameLabel}
            variant="filled"
            required
            InputProps={{ disableUnderline: true }}
            InputLabelProps={{
              sx: {
                pointerEvents: "none",
                color: "black",
                pl: { xs: "12px", sm: "25px" },
              },
            }}
            sx={inputStyle}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="email"
            variant="filled"
            fullWidth
            id="jobabo_email"
            name="jobabo_email"
            label={i18n.translations.jobaboEmailLabel}
            required
            InputProps={{ disableUnderline: true }}
            InputLabelProps={{
              sx: {
                pointerEvents: "none",
                color: "black",
                pl: { xs: "12px", sm: "25px" },
              },
            }}
            sx={inputStyle}
          />
        </Grid>

        <Grid item xs={12} mt={{ xs: 0, md: 1 }}>
          <TextSearchFilterAbo
            filtersParams={filtersParams}
            setFiltersParams={setFiltersParams}
            label={i18n.translations.searchShort}
            sx={inputStyle}
          ></TextSearchFilterAbo>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={5}>
        <Grid item xs={12} lg={6}>
          <AttributeFilterAutocomplete
            label={i18n.translations.filterPosition}
            filterAttributeId={FILTER.Position}
            attributes={attributes}
            filtersParams={filtersParams}
            setFiltersParams={setFiltersParams}
            checkBoxes={true}
          ></AttributeFilterAutocomplete>
        </Grid>
        <Grid item xs={12} lg={6}>
          <AttributeFilterAutocomplete
            label={i18n.translations.filterKanton}
            filterAttributeId={FILTER.Kanton}
            attributes={attributes}
            filtersParams={filtersParams}
            setFiltersParams={setFiltersParams}
            checkBoxes={true}
          ></AttributeFilterAutocomplete>
        </Grid>

        <Grid item xs={12} lg={6}>
          <AttributeFilterAutocomplete
            label={i18n.translations.filterSpecialisation}
            filterAttributeId={FILTER.Fachrichtung}
            attributes={attributes}
            filtersParams={filtersParams}
            setFiltersParams={setFiltersParams}
            checkBoxes={true}
          ></AttributeFilterAutocomplete>
        </Grid>
        <Grid item xs={12} lg={6}>
          <AttributeFilterAutocomplete
            label={i18n.translations.filterCompany}
            filterAttributeId={FILTER.Unternehmen}
            attributes={attributes}
            filtersParams={filtersParams}
            setFiltersParams={setFiltersParams}
            checkBoxes={true}
          ></AttributeFilterAutocomplete>
        </Grid>

        {/* <Grid item xs={9} sm={6} md={4} mt={4}>
          <WorkloadFilterDropdown
            filtersParams={filtersParams}
            setFiltersParams={setFiltersParams}
          />
        </Grid> */}

        <Grid item xs={12} md={10} mt={{xs:3, sm:5}}>
          <Box display="flex" alignItems="start" gap="10px">
            <Checkbox
              className="terms-check"
              disableRipple
              checkedIcon={<BsCheckSquare />}
              required
              icon={<BsSquare />}
              inputProps={{
                "aria-label": i18n.translations.acceptAboTerms,
              }}
            />
            <div className="abo-terms-label">{i18n.translations.aboTermsLabel}</div>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent={{ xs: "flex-start", md: "flex-end" }}
          my={4}
        >
          <button className="main-btn" id="abo-submit" type="submit">
            {i18n.translations.jobaboModalButtonLabel}
          </button>
        </Grid>
      </Grid>
    </JobAboSubscriptionForm>
  );
};

export default JobAboForm;
