export function childrenSelected(parentItem, selectedItems, allOptions) {
  // Returns either "none", "partial" or "all" for a parent attribute, reflecting how many of its children are selected. used in AttributeFilterAutoComplete & AttributeFilterCheckbox
  if (Array.isArray(selectedItems) && Array.isArray(allOptions)) {
    const currentItem = allOptions.find((item) => parentItem.id === item.id);

    if (currentItem.hasOwnProperty("group")) {
      let allChildren = allOptions.filter(
        (item) => item.hasOwnProperty("parent") && item.parent === currentItem.id
      );

      // Check if all child items are in selectedItems
      if (
        allChildren.every((child) => selectedItems.some((selected) => selected.id === child.id))
      ) {
        return "all";
      }

      // Check if some child items are in selectedItems
      else if (
        allChildren.some((child) => selectedItems.some((selected) => selected.id === child.id))
      ) {
        return "partial";
      }

      // If neither of the above conditions are met, none of the child items are in selectedItems
      else {
        return "none";
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export function findChildren(parentId, list) {
  if (Array.isArray(list) && list.length > 0) {
    return list.filter((item) => item.parent == parentId);
  } else {
    return [];
  }
}

export const getChangedItem = (oldVal, newVal) => {
  if (Array.isArray(oldVal) && Array.isArray(newVal)) {
    if (oldVal.length < newVal.length) {
      // Item added
      return newVal.find((opt) => !oldVal.includes(opt));
    } else {
      // Item removed
      return oldVal.find((opt) => !newVal.includes(opt));
    }
  } else {
    return null;
  }
};
