import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import coopLogo from "../assets/images/coop_logo.png";
import marcheLogo from "../assets/images/marche_logo.png";
import idmsLogo from "../assets/images/idms-logo.png";
import fustLogo from "../assets/images/Fust_Logo.png";
import {MenuItem, Select, Menu} from "@mui/material";
import { GoChevronUp } from "react-icons/go";
import { GoChevronDown } from "react-icons/go";

const brandToImage = {
  coop: coopLogo,
  marche: marcheLogo,
  idms: idmsLogo,
  fust: fustLogo,
};


export default function Header({ filtersParams , brand}) {

  const [logo, setLogo] = useState(coopLogo);

  useEffect(() => {
    setLogo(brandToImage[brand] || coopLogo);
  }, [brand]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentLang, setCurrentLang] = useState("de");

  useEffect(() => {
    setCurrentLang(filtersParams.lang);
  }, []);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  const handleLangChange = (newLang) => {
    // const newLang = event.target.value;
    setCurrentLang(newLang);
    const currentSearchParams = new URLSearchParams(window.location.search);
    currentSearchParams.set("lang", newLang);
    const newSearchParams = currentSearchParams.toString();
    window.location.href = `${window.location.pathname}?${newSearchParams}${window.location.hash}`;
    console.log("updating lang to: " + newLang);
  };

  return (
    <header>
      <Box id="header-wrapper">
        <a
          target="_blank"
          className="header-logo"
          href={brand === 'marche' ? `https://www.marche-schweiz.ch/${currentLang}.html` : brand === 'fust' ? `https://www.fust.ch/${currentLang}/footer/offene-stellen` : `https://www.coopjobs.ch/${currentLang}.html`}
        >
          {" "}
          <img className={brand + "-logo slide-in-right"} src={logo} alt="logo"></img>
        </a>

        <div className="header-btns slide-in-left">
          <div className="flex-center">
          {/* <Select
        IconComponent={ GoChevronDown}
        value={currentLang}
        onChange={handleLangChange}
        MenuProps={{
          
        }}
      >
        {["de", "fr", "it"].map((lang) => (
          <MenuItem key={lang} disabled={currentLang === lang} value={lang}>
            {lang.toUpperCase()}
          </MenuItem>
        ))}
      </Select> */}
            {/* <Link to="/" id="jobs-btn" className="main-btn">
              Jobs
            </Link> */}
            <button onClick={handleMenuOpen} className="outline-btn" id="lang-btn">
              {currentLang}
              {anchorEl ? <GoChevronUp /> : <GoChevronDown />}
            </button>
            <Menu
              slotProps={{
                paper: {
                  elevation: 1,
                  className:"lang"
                },
              }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {["de", "fr", "it"].map((lang) => (
                <MenuItem
                  key={lang}
                  disabled={currentLang === lang}
                  onClick={() => handleLangChange(lang)}
                >
                  {lang.toUpperCase()}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>
      </Box>
    </header>
  );
}
