import React, { createContext, useContext, useEffect, useState } from "react";
import { useSettings } from "./SettingsContext";

// used hooks

import { useUrlParams } from "./UrlParams";

// Context for accessing Job Filters ("Attributes") of the current projects from anywhere in the app (see React Context)
// Job attributes are typically loaded once from the backend and then cached in the context.
const FiltersParamsContext = createContext(undefined);

// Hook to provide access to context object.
// const useFiltersParams = () => {
//   return useContext(FiltersParamsContext);
// };

// const FiltersParamsContextProvider = (props) => {
//   const [filtersParams, setFiltersParams] = useState(useUrlParams());

//   return (
//     <FiltersParamsContext.Provider value={{ filtersParams, setFiltersParams }}>
//       {props.children}
//     </FiltersParamsContext.Provider>
//   );
// };

/**
 * Hook to manage the filter parameters for the job list. Initalized by useUrlParams (e.g. from window.location.search)
 * @returns {filtersParams, setFiltersParams}
 */
const useFiltersParams = () => {
  const defaultFiltersParamsFromUrl = useUrlParams(); // Initialize from given URL parameters

  const [filtersParams, setFiltersParams] = useState(
    defaultFiltersParamsFromUrl
  );

  return { filtersParams, setFiltersParams };
};

export { useFiltersParams };
