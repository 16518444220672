/* List of currently active filters in orange text. Excluding filterId 50 labels as that is managed via components/JobTypes as a different page set-up */
import React, { useEffect, useState } from "react";
import { i18n } from "../util/i18n";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ActiveFilters = ({ filtersParams, attributes, setFiltersParams, jobType , mainFilterIds}) => {
  useEffect(() => {
    updateFilterLabels();
  }, [filtersParams, jobType]);

  const [filterLabels, setFilterLabels] = useState([]);

  const filterLabelList = filterLabels.map((label, index) => (
    // hide "Lehrstellen" and "Schnupperlehre" if page is already prefiltered via jobType

    <button
      aria-label={i18n.translations.removeFilter}
      onClick={() => removeFilter(label)}
      className="text-btn active-filter"
      key={index}
    >
      {label.text} 
      <CloseIcon/>
    </button>
  
  ));

  const findLabel = (attributeId, valueId) => {
    let chosenAttribute = attributes.find((item) => item.id == attributeId);
    let chosenLabel = chosenAttribute.values[valueId];
    return chosenLabel;
  };

  const updateFilterLabels = () => {
    let newLabels = [];

    const workload = filtersParams["workload"];
    if (workload) {
      if (!Array.isArray(workload) || workload[0] !== 10 || workload[1] !== 100) {
        newLabels.push({
          parentAttribute: "workload",
          id: null,
          text: workload[0] + "-" + workload[1] + "%",
        });
      }
    }

    //set filter labels
    let filters = { ...filtersParams["f"] };
    // don't show any filter 50 values if already on prefiltered job type page OR  we're on the "landing page" prefiltering out the grundbildung
    if (jobType !== "main" || (jobType == "main" && filters[50] == mainFilterIds.join(",") )) {
      delete filters[50];
    }

    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        const value = filters[key];
        const valueStr = value.toString()
        if (valueStr.includes(",")) {
          let valueArr = valueStr.split(",");
          valueArr.forEach((valId) =>
            newLabels.push({ parentAttribute: key, id: valId, text: findLabel(key, valId) })
          );
        } else {
          //only one filter per category selected
          if (value !== "") {
            newLabels.push({ parentAttribute: key, id: value, text: findLabel(key, value) });
          }
        }
      }
    }

    setFilterLabels(newLabels);
  };

  const removeFilter = (filterItem) => {
    let newFilterParams = {
      ...filtersParams,
      offset: 0,
    };

    if (filterItem.parentAttribute === "workload") {
      newFilterParams.workload = null;
    } else {
      let currentFilterIds = filtersParams.f[filterItem.parentAttribute];
      const currentFilterIdsStr = currentFilterIds.toString()
      if (currentFilterIdsStr.includes(",")) {
        let filterArr = currentFilterIdsStr.split(",").filter((item) => item !== filterItem.id);
        newFilterParams.f[filterItem.parentAttribute] = filterArr;
      } else {
        console.log("Delete only filter for this category")
        if(jobType == "main" && filterItem.parentAttribute == 50){
          //Restore prefiltered main list when the last Position filter is removed
          newFilterParams.f[filterItem.parentAttribute] = mainFilterIds.join(",")
        }else{
          delete newFilterParams.f[filterItem.parentAttribute];
        }
        
      }
    }

    setFiltersParams(newFilterParams);
  };

  return (
    <Box ml="n1" mb={filterLabelList.length > 0 ? 11 : 0} display="flex" flexWrap="wrap">
      {filterLabelList}
    </Box>
  );
};
export default ActiveFilters;
