import { Autocomplete, Badge, Checkbox, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { i18n } from "../util/i18n";
import { childrenSelected, getChangedItem } from "../util/arrHelpers";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { GoChevronDown } from "react-icons/go";

const dropdownIcon = (
  <GoChevronDown
    style={{ fill: "#333333", fontSize: "25px", marginRight: "" }}
  />
);

/**
 * A flexible Autocomplete component for Attribute filters.
 * Supports single or multiple selections.
 * For multiple selections, the values are joined with a comma.
 */

/**
 * This converts the project filter attributes into a format that can be used with our Autocomplete.
 * It will return  a flat list, where hierarchical filters get a "group=true" property, and the value is the string concatenation of all child ids.
 * For all non-hierarchical filters, the value is the id of the of the attribute.
 */
const convertToOptionsList = (attributes, attributeId) => {
  let options = [];
  let sorted_values = [];
  let topLevelOptions = attributes
    .filter((item) => item.id == attributeId)
    .map((attribute) => attribute.sorted_values)[0];

  //console.log("Top Level Options " + attributeId, topLevelOptions);

  topLevelOptions.map((topLevelOption) => {
    let option = {
      id: topLevelOption["id"],
      label: topLevelOption["name"],
      value: topLevelOption["id"],
    };

    // push the top level option
    options.push(option);

    let childAttributes = attributes.find(
      (item) => item.parent_value_id == option.id
    )?.sorted_values;
    // do we have child attributes?
    if (childAttributes) {
      option.value = ""; // For groups, value should only include ids of children
      //console.log("--- Child Attributes for ", option.label, childAttributes);
      childAttributes.map((childAttribute) => {
        //console.log("Child Attribute", childAttribute);

        // append to parent option value
        option.group = true;
        option.value = option.value
          ? `${option.value},${childAttribute["id"]}`
          : childAttribute["id"];

        options.push({
          id: childAttribute["id"],
          label: childAttribute["name"],
          value: childAttribute["id"],
          parent: option.id,
        });
      });
    }
  });

  // console.log("All available options", options);
  return options;
};

/**
 * Finds the Options within the options list that match the current filter value.
 * The selectedIdValues are a string of comma-separated ids.
 *
 * @param {*} allOptions
 * @param {*} selectedIdValues
 */

const AttributeFilterAutocomplete = ({
  mainFilterIds,
  filtersParams,
  setFiltersParams,
  jobType,
  attributes,
  filterAttributeId,
  label = "Filter",
  checkBoxes = "false",
  placeholder,
  hideOptions,
  ...props
}) => {
  const getSelectedOptions = (allOptions, selectedIdValues) => {
    if (!selectedIdValues) {
      return [];
    }
    //extra check for attribute 50 which is a part of our prefilter logic
    if ((jobType == "main" && filterAttributeId == 50 && selectedIdValues == mainFilterIds.join(",") ) ) {
      //console.log("AUTOCOMPLETE: mock empty state")
      return [];
    }
    let selectedOptions = [];
    //console.log("---AUTOCOMPLETE SELECTED", JSON.stringify(selectedIdValues));
  
    selectedIdValues
      .toString()
      .split(",")
      .map((id) => {
        let option = allOptions.find((item) => item.id == id);
        if (option) {
          selectedOptions.push(option);
        }
      });
  
    //console.log("selected", selectedOptions);
  
    return selectedOptions;
  };
  
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const partialIcon = <IndeterminateCheckBoxIcon fontSize="small" />;
  const parentIcon = (item) => {
    const childState = childrenSelected(item, selectedOptions, options);
    if (childState === "partial") {
      return partialIcon;
    } else if (childState === "all") {
      return checkedIcon;
    }
    return icon;
  };

  //set selected options only when filtersParams change
  useEffect(() => {
    let listOptions = convertToOptionsList(attributes, filterAttributeId);
    // in case some options need to be hidden, remove them
    if (hideOptions && hideOptions.length > 0) {
      listOptions = listOptions.filter((option) => !hideOptions.includes(option.id));
    }
    setOptions(listOptions);

    setSelectedOptions(
      getSelectedOptions(listOptions, filtersParams["f"] ? filtersParams["f"][`${filterId}`] : "")
    );
  }, [filtersParams]);

/*   useEffect(() => {
    let listOptions = convertToOptionsList(attributes, filterAttributeId);
    // in case some options need to be hidden, remove them
    if (hideOptions && hideOptions.length > 0) {
      listOptions = listOptions.filter((option) => !hideOptions.includes(option.id));
    }
    setOptions(listOptions);
  }, [filterAttributeId]); */

  if (!attributes) {
    return <div>Project Attributes are required</div>;
  }

  if (!filterAttributeId) {
    return <div>Filter attribute ID is required</div>;
  }
  const filterId = filterAttributeId;
  const multiple = true;

  const handleChanged = (event, value) => {
    // keep all filter values, but change ONLY the one that this component is responsible for.
    // Reset offset to zero.

    //item that user clicked on
    const clickItem = getChangedItem(selectedOptions, value);
    const itemIsParent = Boolean(clickItem?.group);

    let filterAttributeList = value
      .map((o) => o.value) // o.value is a comma separated string of ids, or a single id
      .join(",") // make one long string of comma separated ids
      .split(","); // and split again into an array

    if (itemIsParent) {
      const childState = childrenSelected(clickItem, value, options);
      if (childState === "none" || childState === "all") {
        // Little trick: all ids that are duplicate now, should be removed (= toggled)
        filterAttributeList = filterAttributeList.filter((id) => {
          return filterAttributeList.indexOf(id) === filterAttributeList.lastIndexOf(id);
        });
      } else if (childState == "partial") {
        // remove duplicates so that each id is present only once
        filterAttributeList = [...new Set(filterAttributeList)];
      }
    }

    let newFilterParams = {
      ...filtersParams,
      f: {
        ...filtersParams.f,
        [`${filterId}`]: filterAttributeList.join(","),
      },

      offset: 0,
    };

    console.log("Setting new filter params", value, newFilterParams);

    setFiltersParams(newFilterParams);
  };

  return (
    <>
      <Autocomplete
        openOnFocus
        noOptionsText={i18n.translations.noOptions}
        disablePortal={true}
        id={`attribute-filter-${filterId}`}
        fullWidth={true}
        limitTags={3}
        multiple={multiple}
        disableClearable
        popupIcon={dropdownIcon}
        value={selectedOptions}
        onChange={handleChanged}
        componentsProps={{
          popper: {
            modifiers: [
              {
                name: "flip",
                enabled: false,
              },
            ],
          },
        }}
        options={options}
        renderTags={(value, getTagProps, ownerState) => {
          return (
            <Badge
              sx={{ position: "absolute", left: "5px", fontSize: "17px", color:"#fffff" }}
              size="30px"
              color="darkgrey"
              badgeContent={ownerState.value.length}
            ></Badge>
          );
        }}
        disableCloseOnSelect
        getOptionLabel={(option) => option?.label}
        isOptionEqualToValue={(option, value) => option.id == value.id}
        renderOption={(props, option, { selected }) => (
          <div {...props} className="autocomplete-list-item">
            {checkBoxes && (
              <Checkbox
                icon={option.parent ? icon : parentIcon(option)}
                checkedIcon={checkedIcon}
                checked={selected}
                sx={{ py: 0 }}
              />
            )}
            {option.label}
          </div>
        )}
        renderInput={(params) => (
          <TextField
            variant="outlined"
            {...params}
            placeholder={placeholder || label}
            sx={{
              "& .MuiOutlinedInput-root": {
                height: { xs: "38px", sm: "50px" },
                py: { xs: "0px", sm: "6px" },
                pl: 3,
                "& fieldset": {
                  padding: "0",
                },
                "& input::placeholder": {
                  fontSize: { xs: "13px", sm: "15px" },
                },

                "& .MuiAutocomplete-input": {
                  p: "0px",
                },
              },
            }}
          />
        )}
      />
    </>
  );
};

export default AttributeFilterAutocomplete;
