import * as React from "react";
import { useSettings } from "../util/SettingsContext";
import { i18n } from "../util/i18n";
import { Grid } from "@mui/material";

/**
 * This is a reusable Load More component for Job Lists.
 * It uses the filtersParams object to get the current offset and limit (= page size) and also the total number of jobs for the query.
 * If you pass in a setFilterParams function, it will be called when the user clicks on the Load More button with the updated offset property
 * (so that you can then also fetch jobs from the next page, etc).
 *
 * @param {} filtersParams The current filtersParams object. Important properties: offset, limit
 * @param {*} setFilterParams A useState setter function for the filtersParams object
 * @param {*} totalJobs Total number of jobs for the query. This is used to calculate the number of pages.
 * @param {*} loading if True the component will display a loading indicator instead of the pagination control
 * @param {*} props Will be spread onto the Pagination component. You can use this e.g. for custom styling via sx={{...}} property.
 *
 * @returns JobListLoadMore component
 */
const JobListLoadMore = ({
  filtersParams,
  setFiltersParams,
  totalJobs,
  loading = false,
  ...props
}) => {
  const { settings, setSettings } = useSettings();

  const totalPages = Math.ceil(totalJobs / filtersParams?.limit);

  let empty = !(totalPages >= 1);

  let error =
    !filtersParams ||
    !setFiltersParams ||
    filtersParams.offset < 0 ||
    filtersParams.limit <= 0 ||
    filtersParams.offset > totalJobs;

  const handleLoadMore = () => {
    setFiltersParams({
      ...filtersParams,
      offset: 0,
      limit: filtersParams.limit + settings.jobsPageSize,
      submitted: true,
    });
  };

  return (
    <>
      {error && <div>ERROR: Invalid or missing required properties for JobListLoadMore</div>}

      {totalPages >= 1 && (
        <>
          <Grid container item justifyContent="center" alignItems="center" marginTop={5}>
            <button
              disabled={totalPages == 1}
              className="main-btn"
              id="load-jobs-btn"
              onClick={handleLoadMore}
            >
              {i18n.translations.loadmorejobs}
            </button>
          </Grid>
        </>
      )}
    </>
  );
};

export default JobListLoadMore;
