import React, { useState, useEffect } from "react";

import {
    Card,
    CardContent,
    Fade,
    Grid,
    Skeleton,
    Stack,
    ListItem,

} from "@mui/material";


import { useSettings } from "../util/SettingsContext";


/**
 * This renders a single job for this project as a "list" item.
 * You may want to use `JobListItem` with children, the "Favourite" component etc.
 *
 * This component will be rendered into a MUI `List`
 * See: https://mui.com/material-ui/react-list/
 * API: https://mui.com/material-ui/api/list/
 *
 * For Transitions see MUI docs,
 * especially: https://mui.com/material-ui/transitions/#child-requirement
 *
 * @param {*} job
 */
const JobListItemSkeleton = () => {
    function generateSkeletons(count, height=30) {
        // Create an array of 'count' Skeleton components
        const skeletons = [];
        for (let i = 0; i < count; i++) {
          skeletons.push(
            <Skeleton key={i} variant="rounded" height={height} />
          );
        }
        return (
          <Stack spacing={4}>
            {skeletons}
          </Stack>
        );
      }

    return (
        <Fade in={true} timeout={500}>
            <ListItem className="job-list-item skeleton" sx={{p:3}}>    
                    <Grid container spacing={3} py={1}>
                      <Grid item xs={12}>
                        <Grid container justifyContent="space-between">
                        <Grid item xs={5} lg={2}>
                        {generateSkeletons(1)}
                        </Grid>
                        <Grid item xs={5} lg={2}>
                        {generateSkeletons(1)}
                        </Grid>
                        </Grid>
                        </Grid>     
                        <Grid item xs={12} sm={4}  display={{xs:'none', sm:'initial'}} >
                        {generateSkeletons(2)}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                        {generateSkeletons(3)}                
                        </Grid>
                        <Grid item xs={12} sm={4} display={{xs:'none', sm:'initial'}} >
                        {generateSkeletons(2)}
                        </Grid>
                    </Grid>
   
            </ListItem>
        </Fade>
    );
};

export default JobListItemSkeleton ;

// /**
//  * You can do anything here, just make sure to include a unqiue key
//  */
// const dummyItem = ({ job }) => {
//   return <div key={`job-list-item-${job.id}`}>{job.title}</div>;
// };
// export { dummyItem as JobItem };
