import React, { useEffect, useState } from "react";
import { Grid, Typography, Container, Box } from "@mui/material";
import { useFiltersParams } from "../util/FiltersParamsContext";
import { i18n } from "../util/i18n";
import { useSettings } from "../util/SettingsContext";
import { useFetchUrl } from "../util/UrlParams";
import { Link } from "react-router-dom";

import { useFilters } from "../util/FiltersContext";
import { useLoadJobs } from "../util/QueryService";

import { useDebounce } from "ahooks";

/**
 * Import your components here
 */
import ScrollBtn from "../components/buttons/ScrollBtn";
import JobListLoadMore from "../components/JobListLoadMore";
import JobTypes from "../components/JobTypes";
import Footer from "../components/Footer";
import AttributeFilterAutocomplete from "../components/AttributeFilterAutocomplete";
import ResetFiltersButton from "../components/ResetFiltersButton";
import Header from "../components/Header";
import TextSearchFilter from "../components/TextSearchFilter";
import WorkloadFilterDropdown from "../components/WorkloadFilterDropdown";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// Items are typically project specific
import JobList from "../components/JobList";
import { JobListItem } from "./jobItem";
import arrowRight from "../assets/images/arrow_right.svg";

import LeftIcon from "../assets/images/abo_left.svg";
import RightIcon from "../assets/images/abo_right.svg";
import ActiveFilters from "../components/ActiveFilters";
/**
 * Main Layout for CC
 */
const Layout = ({}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { settings, setSettings } = useSettings();
  const { data: filtersData } = useFilters();

  // This is what we pass to child components to update the filtersParams
  const { filtersParams, setFiltersParams } = useFiltersParams();
  // useDebounce updates the debouncedParams state variable,
  // 200ms after filtersParams has been changed (avoid flickering)
  const debouncedParams = useDebounce(filtersParams, {
    wait: 100,
  });

  // internal state will get updated with a delay after an update.
  // Changes to this will trigger a reload of the jobs list
  const [internalParams, setInternalParams] = useState(filtersParams);

  // Flag, if a "submit" has been triggered. If settings.autoSubmit = false,
  // changes to filtersParams only get applied (and trigger a reload of new job list)
  // if this has been set to true. Flag gets cleared after every submit
  const [triggerSubmit, setTriggerSubmit] = useState(false);

  // create the fetch url from the current internalParams and load the job list from the backend
  const url = useFetchUrl(internalParams);
  const { data: jobsData } = useLoadJobs(url);

  // Prefilter to include all positions (attribute 50) except the grundbildung ones when job type is "main". used by ActiveFilters and Autocomplete to "mock" an appearance that none are selected when actually ?f=50:mainFilterIds.join(",")
  const allPositions = filtersData.attributes.find((attribute) => attribute.id == 50).values;
  const grundBildungAttributes = {
    lehrstellen: "1024532",
    schnupper: "1208595",
  };
  const grundBildungIds = Object.values(grundBildungAttributes);
  const mainFilterIds = Object.keys(allPositions).filter((key) => !grundBildungIds.includes(key));

  useEffect(() => {
    // if autoSubmit, we apply the changed immediatly
    if (settings.autoSubmit) {
      setInternalParams(filtersParams);
    } else {
      // if NOT autoSubmit, we apply the changes only once triggerSubmit flag has been set
      if (triggerSubmit) {
        setInternalParams(filtersParams);
      }
    }
    // in any case, clear the submit trigger flag
    setTriggerSubmit(false);
  }, [debouncedParams, triggerSubmit]);

  const FILTER = settings.filterIds;

  //this controls which "Stellen/Lehrstellen/Schnupperlehre" button is marked "active". job items also check it and style themselves differently. Alternative Autocomplete selection when  "Lehrstelle" or "Schnupperlehre" is chosen
  const [jobType, setJobType] = useState("main");
  const handlePageTypeChange = (newType) => {
    setJobType(newType);
  };

  useEffect(() => {
    // conclude the active job type from filterParams
    if (filtersParams.f && filtersParams.f.hasOwnProperty("50")) {
      const preFilterId = filtersParams.f["50"][0];
      if (preFilterId == grundBildungAttributes.lehrstellen) {
        handlePageTypeChange("Lehrstelle");
        console.log("Prefilter: Lehrstellen");
      } else if (preFilterId == grundBildungAttributes.schnupper) {
        handlePageTypeChange("Schnupperlehre");
        console.log("Prefilter: Schnupperlehre");
      } 
    }
  }, []);

  const [brand, setbrand] = useState("");

  useEffect(() => {
    // in case url has ?theme=marche, apply their theme
    const currentSearchParams = new URLSearchParams(window.location.search);
    const themeParam = currentSearchParams.get("theme");

    if (themeParam === "marche") {
      setbrand("marche");
    } else if (themeParam === "idms") {
      setbrand("idms");
    } else if (themeParam === "fust") {
      setbrand("fust");
    } else {
      setbrand("coop");
    }
  }, []);

  /**
   * Career center main page
   */
  return (
    <>
      {brand && (
        <Container className={brand}>
          <div>
            <Header brand={brand} filtersParams={filtersParams} />

            <div id="content-wrapper">
              <Typography
                id="main-title"
                variant="h1"
                dangerouslySetInnerHTML={{
                  __html:
                      brand === "fust"
                          ? i18n.translations.sloganFust
                          : jobType === "main"
                          ? i18n.translations.slogan
                          : i18n.translations.sloganCharacter,
                }}
              ></Typography>

              {/* Filter Section */}
              <Grid
                container
                spacing={2}
                marginTop={2}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <div className="search-wrapper">
                    <div className="flex-center">
                      <div className="search-intro">
                        {i18n.translations.searchLabelTop}
                        <br></br> {i18n.translations.searchLabelBottom}
                      </div>
                      <TextSearchFilter
                        filtersParams={filtersParams}
                        setFiltersParams={setFiltersParams}
                        isMobile={isMobile}
                        label={
                          isMobile
                            ? i18n.translations.searchShort
                            : i18n.translations.searchPlaceholder
                        }
                      ></TextSearchFilter>
                    </div>

                    <button className="outline-btn" id="search-btn">
                      <img src={arrowRight} role="presentation" alt="" aria-hidden="true"></img>
                    </button>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  display="flex"
                  rowGap={{ xs: 5, md: 0 }}
                  flexDirection={{ xs: "column", md: "row" }}
                  alignItems="center"
                  justifyContent="space-between"
                  mt={{ xs: "86px" }}
                >
                  <JobTypes
                    jobType={jobType}
                    handlePageTypeChange={handlePageTypeChange}
                    filterAttributeId={FILTER.Position}
                    attributes={filtersData.attributes}
                    filtersParams={filtersParams}
                    setFiltersParams={setFiltersParams}
                    mainFilterIds={mainFilterIds}
                    grundBildungAttributes={grundBildungAttributes}
                  ></JobTypes>

                  <Link to="/jobabo" className="main-btn" id="job-abo-btn">
                    <img src={LeftIcon} alt="" role="presentation" aria-hidden="true" height="12" />
                    <div>{i18n.translations.jobaboButtonLabel}</div>
                    <img
                      src={RightIcon}
                      alt=""
                      height="12"
                      role="presentation"
                      aria-hidden="true"
                    />
                  </Link>
                </Grid>

                <Grid
                  mt={{ xs: 4, sm: 7 }}
                  item
                  xs={12}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <h4>Filter</h4>
                  <ResetFiltersButton
                    mainFilterIds={mainFilterIds}
                    filtersParams={filtersParams}
                    setFiltersParams={(value) => {
                      setFiltersParams(value);
                      setTriggerSubmit(true);
                      setJobType("main");
                    }}
                    setTriggerSubmit={setTriggerSubmit}
                  />
                </Grid>
              </Grid>

              <Grid container rowSpacing={{ xs: 1, sm: 2 }} spacing={2} mt={{ xs: 2, sm: 4 }}>
                <Grid item xs={12} md={6}>
                  {jobType === "main" ? (
                    <AttributeFilterAutocomplete
                      label={i18n.translations.filterPosition}
                      filterAttributeId={FILTER.Position}
                      attributes={filtersData.attributes}
                      filtersParams={filtersParams}
                      setFiltersParams={setFiltersParams}
                      checkBoxes={true}
                      hideOptions={grundBildungIds}
                      mainFilterIds={mainFilterIds}
                      jobType={jobType}
                    ></AttributeFilterAutocomplete>
                  ) : (
                    <AttributeFilterAutocomplete
                      label={i18n.translations.filterApprentice}
                      filterAttributeId={FILTER[jobType]}
                      attributes={filtersData.attributes}
                      filtersParams={filtersParams}
                      setFiltersParams={setFiltersParams}
                      checkBoxes={true}
                      hideOptions={["1386811", "1386846"]}
                    ></AttributeFilterAutocomplete>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <AttributeFilterAutocomplete
                    label={i18n.translations.filterKanton}
                    filterAttributeId={FILTER.Kanton}
                    attributes={filtersData.attributes}
                    filtersParams={filtersParams}
                    setFiltersParams={setFiltersParams}
                    checkBoxes={true}
                  ></AttributeFilterAutocomplete>
                </Grid>

                <Grid item xs={12} md={6}>
                  {jobType === "main" ? (
                    <AttributeFilterAutocomplete
                      label={i18n.translations.filterSpecialisation}
                      filterAttributeId={FILTER.Fachrichtung}
                      attributes={filtersData.attributes}
                      filtersParams={filtersParams}
                      setFiltersParams={setFiltersParams}
                      checkBoxes={true}
                    ></AttributeFilterAutocomplete>
                  ) : (
                    <AttributeFilterAutocomplete
                      label={i18n.translations.filterBranch}
                      filterAttributeId={FILTER.Branch}
                      attributes={filtersData.attributes}
                      filtersParams={filtersParams}
                      setFiltersParams={setFiltersParams}
                      checkBoxes={true}
                      hideOptions={["1389234"]}
                    ></AttributeFilterAutocomplete>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <AttributeFilterAutocomplete
                    label={i18n.translations.filterCompany}
                    filterAttributeId={FILTER.Unternehmen}
                    attributes={filtersData.attributes}
                    filtersParams={filtersParams}
                    setFiltersParams={setFiltersParams}
                    checkBoxes={true}
                  ></AttributeFilterAutocomplete>
                </Grid>
                
               {jobType === "main" && (
                  <Grid item xs={9} sm={6} md={4} mt={4}>
                    <WorkloadFilterDropdown
                      filtersParams={filtersParams}
                      setFiltersParams={setFiltersParams}
                    />
                  </Grid>
                )} 

                <Grid item xs={12} mt={3}>
                  <ActiveFilters
                    jobType={jobType}
                    attributes={filtersData.attributes}
                    filtersParams={filtersParams}
                    setFiltersParams={setFiltersParams}
                    mainFilterIds={mainFilterIds}
                  />
                </Grid>
              </Grid>

              {/* Jobs Results*/}
              <Grid container>
                <Grid item xs={12}>
                  <JobList
                    jobsData={jobsData}
                    jobsPageSize={settings.jobPageSize}
                    enableFavourites={settings.enableFavourites}
                    renderJobItem={(job) => <JobListItem brand={brand} key={job.id} job={job} />}
                    notFoundComponent={<div>{i18n.translations.noJobs}</div>}
                  ></JobList>
                </Grid>
              </Grid>

              <JobListLoadMore
                filtersParams={filtersParams}
                setFiltersParams={(value) => {
                  setFiltersParams(value);
                  setTriggerSubmit(true);
                }}
                totalJobs={jobsData?.total}
              />
              <ScrollBtn brand={brand} />
            </div>
          </div>

          <Footer brand={brand} />
        </Container>
      )}
    </>
  );
};

export default Layout;
