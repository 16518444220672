export default {
  // baseUrl: "https://coop.ohws.jobbooster.prospective.ch/public/v1/medium/1000103",
  // jobAboSubscriptionFormUrl:
  // "https://coop.ohws.jobbooster.prospective.ch/public/v1/careercenter/1000103/createJobAbo",
  baseUrl: "https://ohws.prospective.ch/public/v1/medium/1000103",
  jobAboSubscriptionFormUrl:"https://ohws.prospective.ch/public/v1/careercenter/1000103/createJobAbo",

  routerBasename: process.env.ROUTER_BASENAME || "",
  // not recommended because ugly ../#/.. urls and SEO, but Hashrouting helps with
  // current web server limitations
  // Also, note the location of URL params change for JobAbo etc.
  // BrowserRouter: https://localhost:3000/jobabo?lang=en&q=Foo&f=80:12345 ...
  // HashRouter:   https://localhost:3000/?lang=en&q=Foo&f=80:12345#/jobabo
  useHashRouter: true,

  jobsPageSize: 6,
  showLoadMore: true,

  enableFavourites: false, // enable the favourites feature
  localStorageKeyFavourites: "prospective-jobs-favourites", // key for local storage
  displayAsGrid: false, // enable the job grid view
  jobGridColumns: 4, // number of columns for the job grid view (lg and up)

  autoSubmit: true, // if true, the filters will be submitted automatically when changed
  textSearchFilterDebounceTime: 500, // time in ms to wait before submitting the changes in the TextSearchFilterInput.

  enableCopyToClipboard: true, // if true, there will be an option to copy the job link to the clipboard - only visible with hover
  /**
   * TODO
   * Please add your filders and ids here, as defined for the project.
   * Your components will be able to access these ids via Settings.filterIds['Your filter Name'] or FILTER['Your name']
   */
  filterIds: {
    Fachrichtung:20,
    Kanton: 30,
    Position: 50,
    Unternehmen: 70,
    Lehrstelle:350, 
    Schnupperlehre:360,
    Branch:370
  },
  jobAttributes: {
    Institut: 10,
    Pensum: 20,
    Ort: 30,
    ContractType: 40,
    Bereich: 80,
    Bereich_Children: "80_1218671",
  },
};
