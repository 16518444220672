import { createTheme } from "@mui/material/styles";

export const coopTheme = createTheme({
  typography: {
    fontFamily: "'Coop Expanded', system-ui, sans-serif, Helvetica",
  },

  palette: {
    primary: {
      main: "#ea5b0c", //Coop orange
    },
    secondary: {
      main: "#FDEDD0", //Coop light orange
    },
    light: {
      main: "#FFFAF8", //Highlight"
    },
    darkgrey: {
      main: "#333333", //dark grey
    },
  },
});

export const marcheTheme = createTheme({
  typography: {
    fontFamily: "'Maax-Regular-205TF', system-ui, sans-serif, Helvetica",
  },

  palette: {
    primary: {
      main: "#05694B",
    },
    secondary: {
      main: "#05694B",
    },
    light: {
      main: "#FFFAF8",
    },
    darkgrey: {
      main: "#333333",
    },
  },
});

export const idsmTheme = createTheme({
  typography: {
    fontFamily: "'Coop Expanded', system-ui, sans-serif, Helvetica",
  },


  palette: {
    primary: {
      main: "#E30614",
    },
    secondary: {
      main: "#E30614",
    },
    light: {
      main: "#FFFAF8",
    },
    darkgrey: {
      main: "#333333",
    },
  },
});

export const fustTheme = createTheme({
  typography: {
    fontFamily: "'Roboto', system-ui, sans-serif, Helvetica",
  },


  palette: {
    primary: {
      main: "#F6881F",
    },
    secondary: {
      main: "#F6881F",
    },
    light: {
      main: "#FFFAF8",
    },
    darkgrey: {
      main: "##333231",
    },
  },
});