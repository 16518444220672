import React, { useState, useEffect } from "react";
import { Fade, Grid, ListItem, Box } from "@mui/material";
import { useSettings } from "../util/SettingsContext";
import arrowRight from "../assets/images/arrow_right_orange.svg";
import arrowRightWhite from "../assets/images/arrow_right_white.svg";
import arrowRightMarche from "../assets/images/arrow_right_marche.svg";
import arrowRightFust from "../assets/images/arrow_right_fust.svg";
import arrowRightIdms from "../assets/images/arrow_right_idms.svg";
import { i18n } from "../util/i18n";

const brandToImage = {
  coop: arrowRight,
  marche: arrowRightMarche,
  idms: arrowRightIdms,
};

/**
 * This renders a single job for this project
 *
 * @param {*} job
 */
const JobTeaserItem = ({ job, removeFavouriteIcon, brand }) => {
  const { settings } = useSettings();

  const jobPensum = () => {
    if (job.szas["sza_pensum.min"] && job.szas["sza_pensum.max"]) {
      if (job.szas["sza_pensum.min"] == job.szas["sza_pensum.max"]) {
        return job.szas["sza_pensum.max"] + "%";
      } else {
        return job.szas["sza_pensum.min"] + "-" + job.szas["sza_pensum.max"] + "%";
      }
    } else if (job.szas["sza_pensum"]) {
      return job.szas["sza_pensum"];
    }
    return "";
  };

  const [arrow, setArrow] = useState(arrowRight);

  const imageMapping = {
    "Administration / Verwaltung": "Administration.png",
    "Andere Fachrichtungen": "Andere-Fachrichtungen.png",
    "Category Management / Beschaffung": "Category.png",
    "Finanzen / Controlling": "Finanzen.png",
    "Gastronomie / Hotellerie": "Gastronomie.png",
    "Human Resources": "HR.png",
    "Immobilien": "Immobilien.png",
    "Informatik": "IT.png",
    "Logistik / Transport": "Logistik.png",
    "Marketing / Kommunikation / Medien": "Marketing_Komm_Medien.png",
    "Produktion / Technik": "Produktion.png",
    "Verkauf Food": "Verkauf-Food.png",
    "Verkauf Fachformate": "Verkauf-Fachformate.png",
    "Bäcker:in Standard": "Baecker.png",
    "Bäcker:in Produktionsbetriebe": "Baecker.png",
    "Metzger:in": "Metzger.png",
    "Transport": "Transport.png",
    "** bitte auswählen **": "Teaser_Default.png",
  };

  useEffect(() => {
    setArrow(brandToImage[brand] || arrowRight);
  }, [brand]);

  return (
    <div className={`box box-${job.id}`} data-lang={job.language}>
      <div className="title-section">
        <div className="pre-title">
          {job.szas["sza_workplace.city"]}{" "}
          {job.szas["sza_workplace.street"] &&
            `- ${job.szas["sza_workplace.street"]}`}
        </div>
        <div className="title">
          <h2>{job.title}</h2>
          {job.szas["sza_pensum.min"] && (
            <p>{jobPensum()}</p>
          )}
        </div>
      </div>
      <div className="image-cta">
        <img
          src={
            job.attributes?.["380"] && imageMapping[job.attributes["380"][0]]
              ? `https://ohws.prospective.ch/careercenter/1000103/assets/banners/${imageMapping[job.attributes["380"][0]]}`
              : "https://ohws.prospective.ch/careercenter/1000103/assets/banners/Teaser_Default.png"
          }
          title={job.title}
          alt={job.title}
        />
        <div className="action">
          <a
            href={job.links.directlink}
            title={job.title}
            className="button secondary"
            target="_blank"
            onMouseEnter={() => setArrow(arrowRightWhite)}
            onMouseLeave={() => setArrow(brandToImage[brand] || arrowRight)}
          >
            {i18n.translations.toJob}
          </a>
        </div>
      </div>
    </div>
  );
};

export { JobTeaserItem };
