import React from "react";
import { List, Typography } from "@mui/material";
import { useSettings } from "../util/SettingsContext";
import JobListItemSkeleton from "../careercenter/jobItemSkeleton";
import { i18n } from "../util/i18n";
/**
 * Renders a list of jobs, based on the JSON object passed in the props.
 * Uses MUI List components, see: https://mui.com/material-ui/react-list/
 *
 * @param {jobs JSON object, as coming from the API} jobs
 * @returns JSX for the JobList
 */
const TeaserJobList = ({
  jobsData,
  jobPageSize = 3,
  renderTeaserJobItem = (job) => {
    <Typography key={job.id}>{job.title}</Typography>;
  },
  notFoundComponent = <Typography>{i18n.translations.noJobs}</Typography>,
  loading = false,
  disableSkeleton = false,
  displayAsGrid = false,
  children,
  ...props
} = props) => {

  console.log("TeaserJobList component rendered");

  const { settings } = useSettings();
 
  
  // Use window.location.search to access the query parameters
  const queryParams = new URLSearchParams(window.location.search);
  const jobslang = queryParams.get("jobslang");
  const jobslimit = parseInt(queryParams.get("jobslimit")) || null;

  const teaserJobList = jobsData?.jobs ? jobsData.jobs : [];

  if (!Array.isArray(teaserJobList)) {
    return <div key="invalid-jobs">Invalid jobs list, expected an array of job objects</div>;
  }

  if (loading || (!jobsData && !disableSkeleton)) {
    return (
      <List className="job-list">
        {new Array(jobPageSize).fill({}).map((item, idx) => (
          <JobListItemSkeleton key={`skeleton-${idx}`} />
        ))}
      </List>
    );
  }

  // Filter the jobs based on the jobslang query parameter
  let filteredTeaserJobs = teaserJobList.filter((job) => {
    return job.language === jobslang;
  });

  // Limit the number of jobs to the value of jobslimit if specified
  if (jobslimit && filteredTeaserJobs.length > jobslimit) {
    filteredTeaserJobs = filteredTeaserJobs.slice(0, jobslimit);
  }


  return (
    <>

      {filteredTeaserJobs.length > 0 && (
        <div id="jobs">
          {filteredTeaserJobs.map((job) => {
            return renderTeaserJobItem(job);
          })}
        </div>
      )}

      {filteredTeaserJobs.length == 0 && <>{notFoundComponent}</>}
    </>
  );
};

export default TeaserJobList;
