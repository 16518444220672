export default {
  de: {
    metaTitle:"Offene Stellen in der ganzen Schweiz",
    metaDescription:"Auf Jobsuche? Entdecke offene Stellen bei der Coop-Gruppe. Bewirb dich jetzt und starte durch!",
    slogan:"<span>Offene stellen</span><br>der Coop-Gruppe",
    sloganCharacter:"<span>Choose</span> your <span>character</span>",
    sloganFust:"Offene Stellen <span>bei Fust</span>",
    appTitle: "Offene Stellen in der ganzen Schweiz",
    appDescription:"Auf Jobsuche? Entdecke offene Stellen bei der Coop-Gruppe. Bewirb dich jetzt und starte durch!",
    openPositions: "offene-stellen",
    searchPlaceholder: "Hier Suchbegriff eingeben",
    searchShort:"Suchbegriff eingeben",
    showFilters: "Filter anzeigen",
    hideFilters: "Filter schliessen",
    resetFilters: "Alles zurücksetzen",
    submitFilters: "Suchen",
    noJobs: "Keine passende Stelle gefunden",
    workloadLabel: "Pensum",
    jobaboButtonLabel: "Job-Abo",
    jobaboModalButtonLabel: "Job-Abo erstellen",
    filterKanton:"Kanton",
    filterPosition:"Position",
    filterApprentice:"Lehrberuf",
    filterBranch: "Branche",
    filterSpecialisation:"Fachrichtung",
    filterCompany: "Unternehmen",
    jobTypeMain: "Stellen",
    jobTypeApprentice:"Lehrstellen",
    jobTypeApprenticeLabel:"Lehrstellen",
    jobTypeTrial:"Schnupperlehre",
    jobTypeTrialLabel:"Schnupperlehre",
    jobTypePraktikum:"Praktikum / Trainee / Duales Studium",
    workPlace: "Arbeitsort",
    workStart: "Stellenantritt",
    jobaboEmailLabel: "E-Mail Adresse",
    jobaboNameLabel: "Job-Abo Bezeichnung",
    jobaboSubscriptionFormTitle: "Job-Abo erstellen",
    jobaboSubscriptionFormText:"Willst du regelmässig über neue Stellen der Coop-Gruppe informiert werden? Eröffne dein individuelles Suchprofil und werde automatisch über passende Jobs informiert.",
    error:
      "Leider ist beim Laden der Seite ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
    loadmorejobs: "Mehr Stellenangebote laden",
    toJob:"Zum Job",
    aboInfoLabel:"Angaben",
    aboTermsLabel:'Der Versand der Emails des Job-Abonnements erfolgt durch den Drittanbieter prospective media services pms AG mit Sitz in Zürich. Durch das Anklicken des Buttons \"Job Abo erstellen\" erklärst du dich einverstanden, dass deine Email-Adresse sowie die von dir ausgewählten Filterkriterien zum Zweck des Versandes der Job Abonnements Emails an die prospective media services pms AG übermittelt werden. Du hast jederzeit die Möglichkeit, das Job-Abonnement über den Unsubscribe-Link abzubestellen.',
    acceptAboTerms:"Job-Abonnement-Bedingungen akzeptieren",
    removeFilter: "Filter entfernen",
    to:"bis",
    searchLabelTop: "Welchen Job",
    searchLabelBottom: "suchst du?",
    noOptions: "Keine Optionen",
    footerFollow: "Folge uns auf Social Media",
    footerFollowFust: "Folgen Sie uns auf Social Media",
    footerJobs:"Jobs & Karriere",
    footerOpenJobs: "Offene Stellen",
    footerJobabo: "Job-Abo einrichten",
    footerCareers: "Karriere & Weiterbildung",
    footerQuereinstieg: "Quereinstieg",
    footerBlog: "Karriereblog",
    footerAboutUs: "Über uns",
    footerAboutUsFust: "Unternehmen",
    footerEmployer:"Coop als Arbeitgeberin",
    footerEmployerFust: "Arbeiten bei Fust",
    footerWorld:"Unsere Arbeitswelt",
    footerGroup:"Coop-Gruppe",
    footerContactTitle:"Kontakt & Infos",
    footerContact:"Kontakt",
    footerData:"Datenschutz",
    footerImpressum: "Impressum",
    ccLink:"https://coop.ohws.jobbooster.prospective.ch/public/v2/careercenter/1000103/?lang=de",
    footerLearn:"Lernende",
    footerMarche:"Marché Schweiz",
    scrollTop:"Nach oben blättern"
  },
  fr: {
    metaTitle:"Postes vacants dans toute la Suisse",
    metaDescription:"À la recherche d'un emploi ? Découvrez les postes vacants chez le Groupe Coop. Postulez maintenant et lancez votre carrière !",
    slogan:"<span>POSTES VACANTS</span><br>CHEZ LE GROUPE COOP",
    sloganCharacter:"<span>Choose</span> your <span>character</span>",
    sloganFust:"Postes vacants <span>chez Fust</span>",
    appTitle: "Postes vacants dans toute la Suisse",
    appDescription:"À la recherche d'un emploi ? Découvrez les postes vacants chez le Groupe Coop. Postulez maintenant et lancez votre carrière !",
    openPositions: "postes-ouverts",
    searchPlaceholder: "Rechercher",
    searchShort: "Rechercher",
    showFilters: "Afficher les filtres",
    hideFilters: "Fermer les filtres",
    resetFilters: "Tout réinitialiser",
    submitFilters: "Rechercher",
    noJobs: "Aucun poste correspondant trouvé",
    workloadLabel: "Taux d'occupation",
    jobaboButtonLabel: "Alerte offres d'emploi",
    jobaboModalButtonLabel: "Créer une alerte",
    filterKanton: "Canton",
    filterPosition: "Position",
    filterBranch: "Secteur d'activité",
    filterApprentice:"Profession d'apprentissage",
    filterSpecialisation: "Domaine professionnel",
    filterCompany: "Entreprise",
    jobTypeMain: "Offres d'emploi",
    jobTypeApprentice:"Apprentissage",
    jobTypeApprenticeLabel:"Places d'apprentissage",
    jobTypeTrial:"Stage d'orientation",
    jobTypeTrialLabel:"Stage d'orientation professionnelle",
    jobTypePraktikum:"Stage / Trainee / Etudes duales",
    workPlace: "Lieu de travail",
    workStart: "Entrée en fonction",
    jobaboEmailLabel: "Adresse e-mail",
    jobaboNameLabel: "Description de l'alerte offres d'emploi",
    jobaboSubscriptionFormTitle: "Créer une alerte",
    jobaboSubscriptionFormText:
      "Souhaitez-vous être régulièrement informé·e des nouvelles offres d'emploi du groupe Coop? Créez votre profil de recherche pour être systématiquement informé·e des offres d'emploi qui vous correspondent.",
    error:
      "Malheureusement, une erreur s'est produite lors du chargement de la page. Veuillez réessayer ultérieurement.",
    loadmorejobs: "Charger plus d'offres d'emploi",
    toJob: "Voir l'offre d'emploi",
    aboInfoLabel: "Informations",
    aboTermsLabel:
      "Les offres d'emploi sont envoyées par mail par l'entreprise prospective media services pms AG sise à Zurich. En cliquant sur le bouton \"Créer une alerte\", vous acceptez que votre adresse mail et les filtres que vous avez sélectionnés soient transmis à prospective media services pms AG pour vous envoyer les offres d'emploi. Vous avez à tout moment la possibilité de supprimer l'alerte (se désabonner) en cliquant sur le lien prévu à cet effet.",
    removeFilter: "Supprimer le filtre",
    to: "à",
    searchLabelTop: "Quel poste",
    searchLabelBottom: "recherchez-vous ?",
    noOptions: "Aucune option",
    footerFollow: "Suivez-nous sur les réseaux sociaux",
    footerFollowFust: "Suivez-nous sur les réseaux sociaux",
    footerJobs: "Emplois et carrières",
    footerOpenJobs: "Postes vacants",
    footerJobabo: "Créer une alerte",
    footerCareers: "Carrière et formation continue",
    footerQuereinstieg: "Reconversion professionnelle",
    footerBlog: "Blog",
    footerAboutUs: "À propos de nous",
    footerAboutUsFust: "Entreprise",
    footerEmployer: "Coop en tant qu'employeur",
    footerEmployerFust: "Travailler chez Fust",
    footerWorld: "Notre univers professionnel",
    footerGroup: "Groupe Coop",
    footerContactTitle: "Contact et informations",
    footerContact: "Contact",
    footerData: "Protection des données",
    footerImpressum: "Mentions légales",
    ccLink:"https://coop.ohws.jobbooster.prospective.ch/public/v2/careercenter/1000103/?lang=fr",
    footerLearn:"Apprenants",
    footerMarche:"Marché Suisse",
    scrollTop:"Défiler vers le haut de la page"
  },
  it: {
    metaTitle:"Coop	Posizioni aperte in tutta la Svizzera",
    metaDescription:"In cerca di lavoro? Scopri le posizioni aperte nel Gruppo Coop. Candidati ora e inizia la tua carriera!",
    slogan:"<span>POSIZIONI APERTE</span><br>NEL GRUPPO COOP",
    sloganCharacter:"<span>Choose</span> your <span>character</span>",
    sloganFust:"Posizioni aperte <span>nel Fust</span>",
    appTitle: "Posizioni aperte in tutta la Svizzera",
    appDescription:"In cerca di lavoro? Scopri le posizioni aperte nel Gruppo Coop. Candidati ora e inizia la tua carriera!",
    openPositions: "posizioni-aperte",
    searchPlaceholder: "Inserisci termine di ricerca",
    searchShort: "Ricerca",
    showFilters: "Mostra filtri",
    hideFilters: "Nascondi filtri",
    resetFilters: "Resetta",
    submitFilters: "Cerca",
    noJobs: "Nessuna posizione trovata",
    workloadLabel: "Carico di lavoro",
    jobaboButtonLabel: "Job-Abo",
    jobaboModalButtonLabel: "Attiva il Job-Abo",
    filterKanton: "Cantone",
    filterPosition: "Posizione",
    filterBranch: "Ramo",
    filterApprentice:"Apprendistato",
    filterSpecialisation: "Specializzazione",
    filterCompany: "Azienda",
    jobTypeMain: "Offerte di lavoro",
    jobTypeApprentice:"Apprendistato",
    jobTypeApprenticeLabel:"Posti di apprendistato",
    jobTypeTrial:"Tirocinio di prova",
    jobTypeTrialLabel:"Tirocini di prova",
    jobTypePraktikum:"Tirocinio / Trainee / Studio duale",
    workPlace: "Luogo di lavoro",
    workStart: "Entrata in servizio",
    jobaboEmailLabel: "Indirizzo email",
    jobaboNameLabel: "Denominazione Job-Abo",
    jobaboSubscriptionFormTitle: "Attiva il Job-Abo",
    jobaboSubscriptionFormText:
      "Vorresti rimanere sempre al passo con le posizioni di lavoro aperte nel Gruppo Coop? Crea un profilo personalizzato per ricevere gli aggiornamenti.",
    error:
      "Purtroppo si è verificato un errore durante il caricamento della pagina. Riprova più tardi.",
    loadmorejobs: "Carica altre inserzioni",
    toJob: "Vai al lavoro",
    aboInfoLabel: "Dettagli",
    aboTermsLabel:
      "L'invio della Jobmail avviene tramite l'azienda terza prospective media services pms AG con sede a Zurigo. Cliccando su \"Attiva il Job-Abo\" fornisci il tuo consenso alla trasmissione del tuo indirizzo e-mail e dei filtri scelti a prospective media services pms AG per l'invio della Jobmail. Puoi annullare l'iscrizione alla Jobmail in qualsiasi momento tramite l'apposito link",
    removeFilter: "Rimuovi filtro",
    to: "a",
    searchLabelTop: "Che lavoro",
    searchLabelBottom: "cerchi?",
    noOptions: "Nessuna opzione",
    footerFollow: "Seguici sui social media",
    footerFollowFust: "Seguici sui social media",
    footerJobs: "Lavori e carriere",
    footerOpenJobs: "Posizioni vacanti",
    footerJobabo: "Attiva il Job-Abo",
    footerCareers: "Carriera & perfezionamento",
    footerQuereinstieg: "Riqualifica professionale",
    footerBlog: "Blog delle carriere",
    footerAboutUs: "Chi siamo",
    footerAboutUsFust: "Impresa",
    footerEmployer: "Coop come datore di lavoro",
    footerEmployerFust: "Lavorare da Fust",
    footerWorld: "Il nostro mondo del lavoro",
    footerGroup: "Gruppo Coop",
    footerContactTitle: "Contatti & info",
    footerContact: "Contatto",
    footerData: "Protezione dei dati",
    footerImpressum: "Impressum",
    ccLink:"https://coop.ohws.jobbooster.prospective.ch/public/v2/careercenter/1000103/?lang=it",
    footerLearn:"Studenti",
    footerMarche:"Marché Svizzera",
    scrollTop:"Scorri in alto"
  }
};
