/**
 * CareerCenter Page
 * This is the main page for the career center. It contains the filter components and the job list.
 */
import React, { useState, useEffect } from "react";

// import our utility functions for working with filters and languages
import { i18n } from "./util/i18n";

// Hooks to get state from Context objects
// Global settings for this project, from settings.js
import { useSettings } from "./util/SettingsContext";

// Job attributes context for the job attributes (e.g. Berufsgruppe, Institut)
import { useFilters } from "./util/FiltersContext";

// Service to fetch (filtered) jobs from the backend.
import { useLoadJobs } from "./util/QueryService";

// Job filter Parameters. These are modified by the filter components and used to fetch the jobs from the backend API
import { useFiltersParams } from "./util/FiltersParamsContext";

// Helper method to create the full fetch URL
import { useFetchUrl } from "./util/UrlParams";

import { LinearProgress } from "@mui/material";

import Layout from "./careercenter/layout";
import ErrorPage from "./ErrorPage";

/**
 *
 *
 */
function CareerCenterPage() {

  const {
    isLoading: isFiltersLoading,
    isError: isFiltersError,
    data: filtersData,
  } = useFilters();

  const { filtersParams, setFiltersParams } = useFiltersParams();

  const url = useFetchUrl(filtersParams);
     

  const {
    isLoading: isJobsLoading,
    isError: isJobsError,
    data: jobsData,
  } = useLoadJobs(url);
  
  // console.log ("jobsData", jobsData)
  // console.log ("filtersData", filtersData)

  return (
    <>
      {(isJobsLoading || isFiltersLoading) && <LinearProgress />}
      {(isJobsError || isFiltersError) && <ErrorPage />}
      {jobsData && filtersData && <Layout />}
    </>
  );
}

export default CareerCenterPage;