import React from "react";
import { List, Typography } from "@mui/material";
import { useSettings } from "../util/SettingsContext";
import JobListItemSkeleton from "../careercenter/jobItemSkeleton";
import { i18n } from "../util/i18n";
/**
 * Renders a list of jobs, based on the JSON object passed in the props.
 * Uses MUI List components, see: https://mui.com/material-ui/react-list/
 *
 * @param {jobs JSON object, as coming from the API} jobs
 * @returns JSX for the JobList
 */
const JobList = ({
  jobsData,
  jobPageSize,
  renderJobItem = (job) => {
    <Typography key={job.id}>{job.title}</Typography>;
  },
  notFoundComponent = <Typography>{i18n.translations.noJobs}</Typography>,
  loading = false,
  disableSkeleton = false,
  displayAsGrid = false,
  children,
  ...props
} = props) => {
  const { settings } = useSettings();
  const jobList = jobsData?.jobs ? jobsData.jobs : [];

  if (!Array.isArray(jobList)) {
    return <div key="invalid-jobs">Invalid jobs list, expected an array of job objects</div>;
  }

  if (loading || (!jobsData && !disableSkeleton)) {
    return (
      <List className="job-list">
        {new Array(settings.jobsPageSize).fill({}).map((item, idx) => (
          <JobListItemSkeleton key={`skeleton-${idx}`} />
        ))}
      </List>
    );
  }

  return (
    <>
      {jobList.length > 0 && (
        <List className="job-list">
          {jobList.map((job) => {
            return renderJobItem(job);
          })}
        </List>
      )}

      {jobList.length == 0 && <>{notFoundComponent}</>}
    </>
  );
};

export default JobList;
