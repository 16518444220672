import React, { useState, useEffect } from "react";
import { Fade, Grid, ListItem, Box } from "@mui/material";
import { useSettings } from "../util/SettingsContext";
import arrowRight from "../assets/images/arrow_right_orange.svg";
import arrowRightWhite from "../assets/images/arrow_right_white.svg";
import arrowRightMarche from "../assets/images/arrow_right_marche.svg";
import arrowRightFust from "../assets/images/arrow_right_fust.svg";
import arrowRightIdms from "../assets/images/arrow_right_idms.svg";
import { i18n } from "../util/i18n";

const brandToImage = {
  coop: arrowRight,
  marche: arrowRightMarche,
  idms: arrowRightIdms,
  fust: arrowRightFust,
};

/**
 * This renders a single job for this project
 *
 * @param {*} job
 */
const JobListItem = ({ job, removeFavouriteIcon, brand }) => {
  const { settings } = useSettings();

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-indexed, so we add 1
    const year = date.getFullYear().toString();

    return `${day}.${month}.${year}`;
  }

  const jobPensum = () => {
    if (job.szas["sza_pensum.min"] && job.szas["sza_pensum.max"]) {
      if (job.szas["sza_pensum.min"] == job.szas["sza_pensum.max"]) {
        return job.szas["sza_pensum.max"] + "%";
      } else {
        return job.szas["sza_pensum.min"] + "-" + job.szas["sza_pensum.max"] + "%";
      }
    } else if (job.szas["sza_pensum"]) {
      return job.szas["sza_pensum"];
    }
    return "";
  };

  const jobLocation = () => {
    // in case job has more than one kanton listed under attribute 30, show them separated with /. 
    // if only one kanton under attribute 30, try to show a more detailed address. grundbildung jobs use sza_location.city and normal jobs sza_workplace.city.
    const locationAttribute = job.attributes[30] || "";
    if (Array.isArray(locationAttribute) && locationAttribute.length > 1) {
      return locationAttribute.join(" / ");
    } else {
      let location = locationAttribute[0];
      if (isGrundBildung) {
        if (job.szas["sza_location.city"]) {
          location = `${job.szas["sza_location.city"]} ${
            job.szas["sza_location.street"] ? " - " + job.szas["sza_location.street"] : ""
          }`;
        }
      } else {
        if (job.szas["sza_workplace.city"]) {
          location = `${job.szas["sza_workplace.city"]} ${
            job.szas["sza_workplace.street"] ? " - " + job.szas["sza_workplace.street"] : ""
          }`;
        }
      }

      return location;
    }
  };

  // Grundbildung jobs have some info hidden (pensum, start date). To decide if the job is part of grundbildung, we check filter 50 value and if it's the known value of Lehrstelle/Schnupperstelle/Praktikum
  const isGrundBildung =
    job.attributes[50] &&
    (job.attributes[50][0] == i18n.translations.jobTypeApprentice ||
      job.attributes[50][0] == i18n.translations.jobTypeTrial ||
      job.attributes[50][0] == i18n.translations.jobTypePraktikum);

  const [arrow, setArrow] = useState(arrowRight);

  useEffect(() => {
    setArrow(brandToImage[brand] || arrowRight);
  }, [brand]);

  return (
    <ListItem key={`${job.id}-list-item`} className="job-list-item">
      <Fade in={true} timeout={500}>
        <Grid container spacing={{ xs: 0, md: 2 }}>
          <Grid item xs={12} display="flex" justifyContent="space-between" mb={2}>
            <div className="date-label">{formatDate(job.start_date)}</div>
            <img
              src={"https://pms.imgix.net/" + job.szas.sza_company_logo}
              alt={"Logo " + job.attributes[70]}
              aria-hidden="true"
              height="24"
            ></img>
          </Grid>
          <Grid item xs={12} lg={isGrundBildung ? 8 : 4}>
            <h3>{job.title}</h3>
            {!isGrundBildung && (
              <Box
                className={isGrundBildung && "hidden"}
                mt={{ xs: 0, lg: 2 }}
                mb={{ xs: 2, lg: 0 }}
              >
                <p>- {job.attributes[40]}</p>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            {job.attributes[30] && (
              <Box mt={{ xs: isGrundBildung ? 2 : 0, lg: 0 }}>
                <h4>{i18n.translations.workPlace}</h4>
                <p>{jobLocation()}</p>
              </Box>
            )}

            <h4>{i18n.translations.filterCompany}</h4>
            <p>{job.attributes[70]}</p>
            {!isGrundBildung && (
              <>
                <h4>{i18n.translations.filterSpecialisation}</h4>
                <p>{job.attributes[20]}</p>
              </>
            )}
            {isGrundBildung && (
              <>
                <h4>{i18n.translations.filterBranch}</h4>
                <p>{job.attributes[370]}</p>
              </>
            )}
          </Grid>
          {!isGrundBildung && (
            <Grid item xs={12} md={6} lg={4}>
              <h4>{i18n.translations.workStart}</h4>
              <p>{job.szas["sza_starting_date"]}</p>
              {job.szas["sza_pensum"] && (
                <>
                  <h4>{i18n.translations.workloadLabel}</h4>
                  <p>{jobPensum()}</p>
                </>
              )}
            </Grid>
          )}

          <Grid item xs={12} display="flex" justifyContent={{ sm: "flex-start", sm: "flex-end" }}>
            <a
              target="_blank"
              href={job.links.directlink}
              id="job-link-btn"
              className="outline-btn"
              onMouseEnter={() => setArrow(arrowRightWhite)}
              onMouseLeave={() => setArrow(brandToImage[brand] || arrowRight)}
            >
              {i18n.translations.toJob}
              <img width="37" src={arrow} role="presentation" aria-hidden="true" alt=""></img>
            </a>
          </Grid>
        </Grid>
      </Fade>
    </ListItem>
  );
};

export { JobListItem };
