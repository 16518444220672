/* Search field on job abo page */
import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useDebounce } from "ahooks";
import { useSettings } from "../util/SettingsContext";

const TextSearchAbo = ({
  label,
  alignIcon,
  filtersParams,
  isMobile,
  setFiltersParams,
  ...props
}) => {
  const { settings } = useSettings();

  const [internalFilterState, setInternalFilterState] = useState(filtersParams);

  const debouncedFilterState = useDebounce(internalFilterState, {
    wait: settings.textSearchFilterDebounceTime || 500,
  });

  const handleTextSearchChange = (event) => {
    const newFilterParams = {
      ...filtersParams, // keep the existing filter params
      q: event.target.value, // set "q" param to the new value
      offset: 0, // always clear offset when changing filters
    };

    setInternalFilterState(newFilterParams);
  };

  useEffect(() => {
    setFiltersParams(debouncedFilterState);
  }, [debouncedFilterState]);

  // if the filtersParams change, we update our internal state (e.g. when the user clicks "reset")
  useEffect(() => {
    setInternalFilterState(filtersParams);
  }, [filtersParams]);

  return (
    <>
      <TextField
        fullWidth
        onChange={handleTextSearchChange}
        value={internalFilterState?.q ? internalFilterState.q : ""}
        label={label}
        variant="filled"
        InputProps={{ disableUnderline: true }}
        InputLabelProps={{
          sx: {
            color: "black",
            pointerEvents: "none",
            pl: { xs: "12px", sm: "25px" },
          },
        }}
        sx={props.sx}
      />
    </>
  );
};

export default TextSearchAbo;
