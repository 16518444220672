import React from "react";
import { Grid, Box } from "@mui/material";
import { i18n } from "../util/i18n";
import ig_icon from "../assets/images/ig_icon.svg";
import fb_icon from "../assets/images/fb_social_icon.svg";
import fb_icon_fust from "../assets/images/fb_icon_fust.svg";
import x_icon from "../assets/images/x_icon.svg";
import yt_icon from "../assets/images/yt_icon.svg";
import linkedin_icon from "../assets/images/linkedin_icon.svg";

// Requirements for a branded CC were a last minute addition, therefore not the cleanest footer implementation. Based on theme URL param there are normal footer links (coopLinks) or marche version (marcheLinks) when URL has theme=marche. There are also some additional conditionals within the template, check for brand == 'marche' etc
export default function Footer({ brand }) {
  const coopLinks = {
    impressum: {
      de: "https://www.coop.ch/de/unternehmen/impressum.html",
      it: "https://www.coop.ch/it/azienda/impressum.html",
      fr: "https://www.coop.ch/fr/entreprise/impressum.html",
    },
    privacy: {
      de: "https://www.coop.ch/de/unternehmen/datenschutz.html",
      it: "https://www.coop.ch/it/azienda/protezione-dei-dati.html",
      fr: "https://www.coop.ch/fr/entreprise/protection-des-donnees.html",
    },
    group: {
      de: "https://www.coopjobs.ch/de/coop-als-arbeitgeberin/coop-gruppe.html",
      it: "https://www.coopjobs.ch/it/coop-als-arbeitgeberin/coop-gruppe.html",
      fr: "https://www.coopjobs.ch/fr/coop-als-arbeitgeberin/coop-gruppe.html",
    },
    employer: {
      de: "https://www.coopjobs.ch/de/was-wir-bieten.html",
      it: "https://www.coopjobs.ch/it/cosa-offriamo.html",
      fr: "https://www.coopjobs.ch/fr/ce-que-nous-offrons.html",
    },
    entry: {
      de: "https://www.coopjobs.ch/content/jobs/de/was-wir-bieten/quereinstieg.html",
      it: "https://www.coopjobs.ch/it/cosa-offriamo/riqualifica-professionale.html",
      fr: "https://www.coopjobs.ch/fr/ce-que-nous-offrons/reconversion-professionelle.html",
    },
    contact: {
      de: "https://www.coopjobs.ch/de/kontakt.html",
      it: "https://www.coopjobs.ch/it/kontakt.html",
      fr: "https://www.coopjobs.ch/fr/kontakt.html",
    },
    world: {
      de: "https://www.coopjobs.ch/de/unsere-arbeitswelt.html",
      it: "https://www.coopjobs.ch/it/unsere-arbeitswelt.html",
      fr: "https://www.coopjobs.ch/fr/unsere-arbeitswelt.html",
    },
    weiterbildung: {
      de: "https://www.coopjobs.ch/de/coop-als-arbeitgeberin/karriere-und-weiterbildung.html",
      it: "https://www.coopjobs.ch/it/coop-als-arbeitgeberin/karriere-und-weiterbildung.html",
      fr: "https://www.coopjobs.ch/fr/coop-als-arbeitgeberin/karriere-und-weiterbildung.html",
    },
    social: {
      fb: "https://www.facebook.com/coop.ch/",
      ig: "https://www.instagram.com/coop.jobs/",
      twitter: "https://twitter.com/coop_ch",
      yt: "https://www.youtube.com/channel/UCgS_OtFhFq5LTuyGKdafoNA",
      linkedin: "https://www.linkedin.com/company/coop_2/",
    },
  };
  const marcheLinks = {
    impressum: {
      de: "https://www.coop.ch/de/unternehmen/impressum.html",
      it: "https://www.coop.ch/it/azienda/impressum.html",
      fr: "https://www.coop.ch/fr/entreprise/impressum.html",
    },
    privacy: {
      de: "https://www.coop.ch/de/unternehmen/datenschutz/marche.html",
      it: "https://www.coop.ch/it/azienda/protezione-dei-dati/marche.html",
      fr: "https://www.coop.ch/fr/entreprise/protection-des-donnees/marche.html",
    },
    group: {
      de: "https://www.coopjobs.ch/de/coop-als-arbeitgeberin/coop-gruppe.html",
      it: "https://www.coopjobs.ch/it/coop-als-arbeitgeberin/coop-gruppe.html",
      fr: "https://www.coopjobs.ch/fr/coop-als-arbeitgeberin/coop-gruppe.html",
    },
    employer: {
      de: "https://www.coopjobs.ch/de/was-wir-bieten.html",
      it: "https://www.coopjobs.ch/it/cosa-offriamo.html",
      fr: "https://www.coopjobs.ch/fr/ce-que-nous-offrons.html",
    },
    entry: {
      de: "https://www.coopjobs.ch/content/jobs/de/was-wir-bieten/quereinstieg.html",
      it: "https://www.coopjobs.ch/it/cosa-offriamo/riqualifica-professionale.html",
      fr: "https://www.coopjobs.ch/fr/ce-que-nous-offrons/reconversion-professionelle.html",
    },
    contact: {
      de: "https://www.marche-schweiz.ch/de/kontakt.html",
      it: "https://www.marche-schweiz.ch/it/kontakt.html",
      fr: "https://www.marche-schweiz.ch/fr/kontakt.html",
    },
    world: {
      de: "https://www.coopjobs.ch/de/unsere-arbeitswelt.html",
      it: "https://www.coopjobs.ch/it/unsere-arbeitswelt.html",
      fr: "https://www.coopjobs.ch/fr/unsere-arbeitswelt.html",
    },
    weiterbildung: {
      de: "https://www.marche-schweiz.ch/de/karriere.html",
      it: "https://www.marche-schweiz.ch/it/karriere.html",
      fr: "https://www.marche-schweiz.ch/fr/karriere.html",
    },
    social: {
      fb: "https://www.facebook.com/MarcheSchweiz/",
      ig: "https://www.instagram.com/marche.ch/",
      yt: "https://www.youtube.com/channel/UCubqlQPFxkxvzPVVceAuiOw",
      linkedin: "https://ch.linkedin.com/company/marche-restaurants-schweiz",
    },
  };
  const fustLinks = {
    openvacancies: {
      de: "https://fust.ch/footer/offene-stellen/jobs",
      it: "https://fust.ch/it/footer/offene-stellen/jobs",
      fr: "https://fust.ch/fr/footer/offene-stellen/jobs",
    },
    impressum: {
      de: "https://fust.ch/impressum",
      it: "https://fust.ch/it/impressum",
      fr: "https://fust.ch/fr/impressum",
    },
    privacy: {
      de: "https://www.coop.ch/de/unternehmen/datenschutz/fust.html",
      it: "https://www.coop.ch/it/unternehmen/datenschutz/fust.html",
      fr: "https://www.coop.ch/fr/unternehmen/datenschutz/fust.html",
    },
    group: {
      de: "https://www.coopjobs.ch/de/coop-als-arbeitgeberin/coop-gruppe.html",
      it: "https://www.coopjobs.ch/it/coop-als-arbeitgeberin/coop-gruppe.html",
      fr: "https://www.coopjobs.ch/fr/coop-als-arbeitgeberin/coop-gruppe.html",
    },
    about: {
      de: "https://fust.ch/footer/ueber-uns",
      it: "https://fust.ch/it/footer/ueber-uns",
      fr: "https://fust.ch/fr/footer/ueber-uns",
    },
    employer: {
      de: "https://fust.ch/footer/offene-stellen",
      it: "https://fust.ch/it/footer/offene-stellen/jobs",
      fr: "https://fust.ch/fr/footer/offene-stellen/jobs",
    },
    entry: {
      de: "https://www.coopjobs.ch/de/coop-als-arbeitgeberin/quereinstieg.html",
      it: "https://www.coopjobs.ch/fr/coop-en-tant-quemployeur/reconversion-professionnelle.html",
      fr: "https://www.coopjobs.ch/it/coop-come-datore-di-lavoro/riqualifica-professionale.html",
    },
    contact: {
      de: "mailto:jobs@fust.ch",
      it: "mailto:jobs@fust.ch",
      fr: "mailto:jobs@fust.ch",
    },
    world: {
      de: "https://www.coopjobs.ch/de/unsere-arbeitswelt.html",
      it: "https://www.coopjobs.ch/it/unsere-arbeitswelt.html",
      fr: "https://www.coopjobs.ch/fr/unsere-arbeitswelt.html",
    },
    jobabo: {
      de: "https://jobs.coopjobs.ch/?theme=fust&f=70:1114045&lang=de#/jobabo",
      it: "https://jobs.coopjobs.ch/?theme=fust&f=70:1114045&lang=it#/jobabo",
      fr: "https://jobs.coopjobs.ch/?theme=fust&f=70:1114045&lang=fr#/jobabo",
    },
    social: {
      fb: "https://www.facebook.com/fust.ch",
      ig: "https://www.instagram.com/fust.ch",
      yt: "https://www.youtube.com/fusttv",
      linkedin: "https://www.linkedin.com/company/fust",
    },
  };

  let links = {};
  if (brand == "marche") {
    links = marcheLinks;
  } else if (brand == "fust") {
    links = fustLinks;
  } else {
    links = coopLinks;
  }

  return (
    <footer id="footer">
      <Grid container rowGap="30px">
        <Grid item xs={12} sm={6} lg={3}>
          <h4>{i18n.translations.footerJobs}</h4>
          <a
            target="_blank"
            href={
              brand == "marche" ? window.location : brand == "fust" ? links.openvacancies[i18n.lang] : `https://jobs.coopjobs.ch/?lang=${i18n.lang}`
            }
          >
            {i18n.translations.footerOpenJobs}
          </a>
          {brand == "fust" && (
              <a target="_blank" href={links.jobabo[i18n.lang]}>
                {i18n.translations.footerJobabo}
              </a>
          )}
          {brand !== "fust" && (
          <a target="_blank" href={links.weiterbildung[i18n.lang]}>
            {i18n.translations.footerCareers}
          </a>
          )}
          {brand !== "marche" && (
            <a target="_blank" href={links.entry[i18n.lang]}>
              {i18n.translations.footerQuereinstieg}
            </a>
          )}
          {brand !== "marche" && (
            <a target="_blank" href={`https://www.coopjobs.ch/${i18n.lang}/blog.html`}>
              {i18n.translations.footerBlog}
            </a>
          )}
          {brand == "marche" && (
            <a
              target="_blank"
              href={`https://www.marche-schweiz.ch/${i18n.lang}/karriere/lernende.html`}
            >
              {i18n.translations.footerLearn}
            </a>
          )}
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          <h4>{brand === 'fust' ? i18n.translations.footerAboutUsFust : i18n.translations.footerAboutUs}</h4>
          {brand == "marche" && (
            <a target="_blank" href={`https://www.marche-schweiz.ch/${i18n.lang}.html`}>
              {i18n.translations.footerMarche}
            </a>
          )}
          {brand == "fust" && (
              <a target="_blank" href={links.about[i18n.lang]}>
                {i18n.translations.footerAboutUs}
              </a>
          )}
          {brand == "fust" && (
              <a target="_blank" href={links.employer[i18n.lang]}>
                {i18n.translations.footerEmployerFust}
              </a>
          )}
          {brand !== "marche" && brand !== "fust" && (
            <a target="_blank" href={links.employer[i18n.lang]}>
              {i18n.translations.footerEmployer}
            </a>
          )}
          {brand !== "marche" && brand !== "fust" && (
            <a target="_blank" href={links.world[i18n.lang]}>
              {i18n.translations.footerWorld}
            </a>
          )}
          {brand !== "marche" && (
            <a target="_blank" href={links.group[i18n.lang]}>
              {i18n.translations.footerGroup}
            </a>
          )}
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <h4>{i18n.translations.footerContactTitle}</h4>
          <a target="_blank" href={links.contact[i18n.lang]}>
            {i18n.translations.footerContact}
          </a>
          <a target="_blank" href={links.privacy[i18n.lang]}>
            {i18n.translations.footerData}
          </a>
          <a target="_blank" href={links.impressum[i18n.lang]}>
            {i18n.translations.footerImpressum}
          </a>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <div className="follow-us-label">
            {brand === 'fust' ? i18n.translations.footerFollowFust : i18n.translations.footerFollow}
          </div>
          <Box className="follow-icons" display="flex" alignItems="center" gap="15px">
            {brand == "fust" && (
                <>
                  <a
                      href={links.social.ig + "?locale=" + i18n.lang}
                      target="_blank"
                      title="Instagram"
                      rel="noopener noreferrer"
                  >
                    {" "}
                    <img src={ig_icon} width="30px" alt="Instagram logo" />
                  </a>
                  <a href={links.social.yt} target="_blank" title="Youtube" rel="noopener noreferrer">
                    <img src={yt_icon} width="30px" alt="Youtube logo" />
                  </a>
                </>
            )}
            <a
              href={links.social.linkedin}
              target="_blank"
              title="Linkedin"
              rel="noopener noreferrer"
            >
              <img src={linkedin_icon} width="30px" alt="Linkedin logo" />
            </a>
            {brand == "marche" && (
              <a
                href={links.social.fb + "?locale=" + i18n.lang}
                target="_blank"
                title="Facebook"
                rel="noopener noreferrer"
              >
                {" "}
                <img src={fb_icon} width="30px" alt="Facebook logo" />
              </a>
            )}
            {brand != "fust" && (
            <a
              href={links.social.ig + "?locale=" + i18n.lang}
              target="_blank"
              title="Instagram"
              rel="noopener noreferrer"
            >
              {" "}
              <img src={ig_icon} width="30px" alt="Instagram logo" />
            </a>
            )}
            {brand == "coop" && (
              <a href={links.social.twitter} target="_blank" title="X" rel="noopener noreferrer">
                {" "}
                <img src={x_icon} width="30px" alt="X logo" />
              </a>
            )}
            {brand != "fust" && (
            <a href={links.social.yt} target="_blank" title="Youtube" rel="noopener noreferrer">
              <img src={yt_icon} width="30px" alt="Youtube logo" />
            </a>
            )}
            {brand == "fust" && (
                <a
                    href={links.social.fb + "?locale=" + i18n.lang}
                    target="_blank"
                    title="Facebook"
                    rel="noopener noreferrer"
                >
                  {" "}
                  <img src={fb_icon_fust} width="30px" alt="Facebook logo" />
                </a>
            )}
          </Box>
        </Grid>
      </Grid>
    </footer>
  );
}
