import React, { useState, useEffect } from "react";
import upIcon from "../../assets/images/up_icon.svg";
import upIconMarche from "../../assets/images/up_icon_marche.svg";
import upIconIdms from "../../assets/images/up_icon_idms.svg";
import upIconFust from "../../assets/images/up_icon_fust.svg";
import { i18n } from "../../util/i18n";

const brandToImage = {
    coop: upIcon,
    marche: upIconMarche,
    idms: upIconIdms,
    fust: upIconFust,
  };

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

 function ScrollBtn({brand}) {
    const [arrow, setArrow] = useState(upIcon);

    useEffect(() => {
      setArrow(brandToImage[brand] || upIcon);
    }, [brand]);
  

  return (
    <a role="button" id="scroll-btn" onClick={scrollToTop} aria-label={i18n.translations.scrollTop} title={i18n.translations.scrollTop}  >
    <img width="20" src={arrow} />
  </a>
  );
}

export default ScrollBtn