/* Search field on CC page */
import React, { useEffect, useState } from "react";
import { i18n } from "../util/i18n";
import {Button, TextField, Box, IconButton, FormControl, InputLabel, Input, Paper} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { useDebounce } from "ahooks";
import { useSettings } from "../util/SettingsContext";
import { useFiltersParams } from "../util/FiltersParamsContext";

/**
 * It supports debouncing, so the filtersParams (passed down by the parent) are only updated after a period of time.
 * The setting to control this is settings.textSearchFilterDebounceTime
 */

const TextSearchFilterInput = ({
  label,
  alignIcon,
  filtersParams,
  isMobile,
  setFiltersParams,
  ...props
}) => {
  const { settings } = useSettings();
  //const { filtersParams, setFiltersParams } = useFiltersParams();

  // We use a local state to store the current filter state.
  // The debouncing will update te setFilterParams only after a period of time
  const [internalFilterState, setInternalFilterState] = useState(filtersParams);

  // we want to debounce the text search input, so we use the "useDebounce" hook from "ahooks"
  // see: https://ahooks.js.org/hooks/use-debounce
  const debouncedFilterState = useDebounce(internalFilterState, {
    wait: settings.textSearchFilterDebounceTime || 500,
  });

  /**
   * Handle the change of the text search input field.
   * We create a new filtersParams object and update the local state.
   * At this point, the (global) filtersParams are not updated yet.
   */
  const handleTextSearchChange = (event) => {
    const newFilterParams = {
      ...filtersParams, // keep the existing filter params
      q: event.target.value, // set "q" param to the new value
      offset: 0, // always clear offset when changing filters
    };
    // update our internal state
    console.log("newFilterParams", newFilterParams);
    setInternalFilterState(newFilterParams);
  };

  // ONLY after the debounced filter state has changed, we update the (global) filter params
  useEffect(() => {
    setFiltersParams(debouncedFilterState);
  }, [debouncedFilterState]);

  // if the filtersParams change, we update our internal state (e.g. when the user clicks "reset")
  useEffect(() => {
    setInternalFilterState(filtersParams);
  }, [filtersParams]);

  return (
    <>
      <Paper
          className="search"
          sx={{ ml:4, display: 'flex', alignItems: 'center', ...props.sx }}
          elevation={0}
      >
        {alignIcon == "start" &&
            <IconButton type="button" aria-label="search">
              <SearchIcon />
            </IconButton>
        }
        <InputBase
            className={alignIcon}
            fullWidth
            onChange={handleTextSearchChange}
            sx={{width: label.length*10, bgcolor:"white"}}
            placeholder={label}
            variant="standard"
            value={internalFilterState?.q ? internalFilterState.q : ""}
            classes={{ input: '' }} 
        />
        {alignIcon == "end" &&
            <IconButton type="button" aria-label="search">
              <SearchIcon />
            </IconButton>
        }
      </Paper>
    </>
  );
};

export default TextSearchFilterInput;
