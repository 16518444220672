import translations from "../translations";

const queryString =
  window.location.search.length > 1 && window.location.search[0] === "?"
    ? window.location.search.substring(1)
    : window.location.search;

function getQueryStringParams(queryString) {
  return queryString.split("&").reduce((result, currentItem) => {
    const [param, value] = currentItem.split("=");
    result[param] = value;
    return result;
  }, {});
}

const queryParams = getQueryStringParams(queryString);

const translationSet = translations[queryParams.lang]
  ? translations[queryParams.lang]
  : translations.de;

export const i18n = {
  translations: translationSet,
  lang: queryParams.lang || "de",
};
