import React from "react";
import { Typography } from "@mui/material";
import { i18n } from "./util/i18n";
import { useRouteError } from "react-router-dom";

const ErrorPage = () => {
  let error = useRouteError();
  console.error(error);

  return (
    <>
      <Typography variant="strong" sx={{p:4, mt:4}}>{i18n.translations.error}</Typography>
    </>
  );
};

export default ErrorPage;
