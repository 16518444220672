import React, { useEffect, useState } from "react";
import { Grid, Typography, Container, Box } from "@mui/material";
import { useFiltersParams } from "../util/FiltersParamsContext";
import { i18n } from "../util/i18n";
import { useSettings } from "../util/SettingsContext";
import { useFetchUrl } from "../util/UrlParams";
import { Link } from "react-router-dom";

import { useFilters } from "../util/FiltersContext";
import { useLoadJobs } from "../util/QueryService";

import { useDebounce } from "ahooks";

/**
 * Import your components here
 */
import ScrollBtn from "../components/buttons/ScrollBtn";
import JobListLoadMore from "../components/JobListLoadMore";
import JobTypes from "../components/JobTypes";
import Footer from "../components/Footer";
import AttributeFilterAutocomplete from "../components/AttributeFilterAutocomplete";
import ResetFiltersButton from "../components/ResetFiltersButton";
import Header from "../components/Header";
import TextSearchFilter from "../components/TextSearchFilter";
import WorkloadFilterDropdown from "../components/WorkloadFilterDropdown";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// Items are typically project specific
import TeaserJobList from "../components/TeaserJobList";
import { JobTeaserItem } from "./jobItemTeaser";
import arrowRight from "../assets/images/arrow_right.svg";

import LeftIcon from "../assets/images/abo_left.svg";
import RightIcon from "../assets/images/abo_right.svg";
import ActiveFilters from "../components/ActiveFilters";
/**
 * Main Layout for CC
 */
const LayoutTeaser = ({}) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { settings, setSettings } = useSettings();
  const { data: filtersData } = useFilters();

  // This is what we pass to child components to update the filtersParams
  const { filtersParams, setFiltersParams } = useFiltersParams();
  // useDebounce updates the debouncedParams state variable,
  // 200ms after filtersParams has been changed (avoid flickering)
  const debouncedParams = useDebounce(filtersParams, {
    wait: 100,
  });

  // internal state will get updated with a delay after an update.
  // Changes to this will trigger a reload of the jobs list
  const [internalParams, setInternalParams] = useState(filtersParams);

  // Flag, if a "submit" has been triggered. If settings.autoSubmit = false,
  // changes to filtersParams only get applied (and trigger a reload of new job list)
  // if this has been set to true. Flag gets cleared after every submit
  const [triggerSubmit, setTriggerSubmit] = useState(false);

  // create the fetch url from the current internalParams and load the job list from the backend
  const url = useFetchUrl(internalParams);
  const { data: jobsData } = useLoadJobs(url);

  // Prefilter to include all positions (attribute 50) except the grundbildung ones when job type is "main". used by ActiveFilters and Autocomplete to "mock" an appearance that none are selected when actually ?f=50:mainFilterIds.join(",")
  const allPositions = filtersData.attributes.find((attribute) => attribute.id == 50).values;
  const grundBildungAttributes = {
    lehrstellen: "1024532",
    schnupper: "1208595",
  };
  const grundBildungIds = Object.values(grundBildungAttributes);
  const mainFilterIds = Object.keys(allPositions).filter((key) => !grundBildungIds.includes(key));

  useEffect(() => {
    // if autoSubmit, we apply the changed immediatly
    if (settings.autoSubmit) {
      setInternalParams(filtersParams);
    } else {
      // if NOT autoSubmit, we apply the changes only once triggerSubmit flag has been set
      if (triggerSubmit) {
        setInternalParams(filtersParams);
      }
    }
    // in any case, clear the submit trigger flag
    setTriggerSubmit(false);
  }, [debouncedParams, triggerSubmit]);

  const FILTER = settings.filterIds;

  //this controls which "Stellen/Lehrstellen/Schnupperlehre" button is marked "active". job items also check it and style themselves differently. Alternative Autocomplete selection when  "Lehrstelle" or "Schnupperlehre" is chosen
  const [jobType, setJobType] = useState("main");
  const handlePageTypeChange = (newType) => {
    setJobType(newType);
  };

  useEffect(() => {
    // conclude the active job type from filterParams
    if (filtersParams.f && filtersParams.f.hasOwnProperty("50")) {
      const preFilterId = filtersParams.f["50"][0];
      if (preFilterId == grundBildungAttributes.lehrstellen) {
        handlePageTypeChange("Lehrstelle");
        console.log("Prefilter: Lehrstelle");
      } else if (preFilterId == grundBildungAttributes.schnupper) {
        handlePageTypeChange("Schnupperlehre");
        console.log("Prefilter: Schnupperlehre");
      }
    }
  }, []);

  useEffect(() => {
    // in case url has ?theme=marche, apply their theme
    const currentSearchParams = new URLSearchParams(window.location.search);    
  }, []);

  /**
   * Career center main page
   */
  return (
    <>
      <div id="teaser">

        <TeaserJobList
            jobsData={jobsData}
            renderTeaserJobItem={(job) => <JobTeaserItem key={job.id} job={job} />}
            notFoundComponent={<div>{i18n.translations.noJobs}</div>}
        ></TeaserJobList>

      </div>
    </>
  );
};

export default LayoutTeaser;
