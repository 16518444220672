import { Box, FormLabel, Select, MenuItem } from "@mui/material";
import { GoChevronDown } from "react-icons/go";

import React, { useState, useEffect } from "react";
import { i18n } from "../util/i18n";

const ExpandMoreIcon = () => (
  <GoChevronDown
    className="animated-icon"
    style={{
      fill: "#333333",
      fontSize: "25px",
      height: "25px",
      width: "25px",
      position: "absolute",
      right: "10px",
      pointerEvents: "none",
    }}
  />
);

const WorkloadFilterDropdown = ({ filtersParams, setFiltersParams, ...props }) => {
  const [workloadRange, setWorkloadRange] = useState([10, 100]);
  const options = Array.from({ length: 10 }, (_, i) => (i + 1) * 10);

  // useEffect(() => {
  //   setFiltersParams({
  //     ...filtersParams,
  //     workload: workloadRange,
  //     offset: 0,
  //   });
  // }, [workloadRange]);

  useEffect(() => {
    setWorkloadRange(filtersParams?.workload || [10, 100]);
  }, [filtersParams]);

  const handleChangeMin = (e) => {
    const newMin = Number(e.target.value);
    console.log("NEW MIN: " + newMin);
    setWorkloadRange([newMin, Math.max(newMin, workloadRange[1])]);
    setFiltersParams({
      ...filtersParams,
      workload: [newMin, Math.max(newMin, workloadRange[1])],
      offset: 0,
    });
  };

  const handleChangeMax = (e) => {
    const newMax = Number(e.target.value);
    setWorkloadRange([Math.min(newMax, workloadRange[0]), newMax]);
    setFiltersParams({
      ...filtersParams,
      workload: [Math.min(newMax, workloadRange[0]), newMax],
      offset: 0,
    });
  };

  const selectStyle = {
    height: { xs: "38px", sm: "50px" },
    width: { xs: "106px", sm: "123px" },
    "& .MuiSelect-select": {
      height: { xs: "38px", sm: "50px" },
      width: { xs: "106px", sm: "123px" },
      padding: { xs: "6px", sm: "13px" },
      paddingLeft: { xs: "20px", sm: "20px" },
      color: "#333333",
      fontSize: { xs: "13px", sm: "15px" },
    },
  };

  return (
    <>
      <FormLabel className="grey-text" style={{ bottom: "14px"}}>
        {i18n.translations.workloadLabel}:
      </FormLabel>
      <Box className="workload-dropdown">
        <Select
          MenuProps={{
            PaperProps: {
              elevation:1
            },
            classes: {
              paper: "workload-menu",
            }
          }}
          fullWidth
          IconComponent={ExpandMoreIcon}
          value={filtersParams?.workload ? filtersParams.workload[0] : workloadRange[0]}
          onChange={handleChangeMin}
          sx={selectStyle}
        >
          {options.map((val) => (
            <MenuItem key={val} value={val} disabled={val >= workloadRange[1]}>
              {val}%
            </MenuItem>
          ))}
        </Select>

        <div className="grey-text">{i18n.translations.to}</div>

        <Select
          MenuProps={{
            PaperProps: {
              elevation:1
            },
            classes: {
              paper: "workload-menu",
            }
          }}
          fullWidth
          IconComponent={ExpandMoreIcon}
          value={filtersParams?.workload ? filtersParams.workload[1] : workloadRange[1]}
          onChange={handleChangeMax}
          sx={selectStyle}
        >
          {options.map((val) => (
            <MenuItem key={val} value={val} disabled={val <= workloadRange[0]}>
              {val}%
            </MenuItem>
          ))}
        </Select>
      </Box>
    </>
  );
};

export default WorkloadFilterDropdown;
