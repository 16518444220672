import React from "react";
import { i18n } from "../util/i18n";
import CloseIcon from "@mui/icons-material/Close";

/**
 * A React component that resets alls filters to their default values
 */

const ResetFiltersButton = ({
  filtersParams,
  setFiltersParams,
  setTriggerSubmit,
  mainFilterIds,
}) => {
  const handleReset = () => {
    // Reset all filters, keep language and page size, reset offset.
    // All "attribute" filters and workload are removed apart from main prefilter list
    setFiltersParams({
      lang: filtersParams.lang,
      limit: filtersParams.limit,
      offset: 0,
      f: { 50: mainFilterIds.join(",") },
    });
    setTriggerSubmit(true); // reset button needs to always trigger a reload directly
  };

  return (
    <button onClick={handleReset} className="plain-btn">
      {i18n.translations.resetFilters} <CloseIcon />
    </button>
  );
};

export default ResetFiltersButton;
