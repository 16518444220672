/**
 * @file index.js
 *
 * This is the entry point for the React app.
 *
 * We'll setup things like the react-query client, and the global context providers here, theming etc.
 * Typically, you won't need to edit this file.
 * For more information on React Context, see https://reactjs.org/docs/context.html
 *
 */

import React, { useState, useEffect, createContext } from "react";
import { createRoot } from "react-dom/client";
import { i18n } from "./util/i18n";

// React Router, used for routing with the page URL, history etc.
import {
  createBrowserRouter,
  createHashRouter,
  Outlet,
  RouterProvider,
} from "react-router-dom";

// Changing CSS injection order to be able to use our stylesheet without !important
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import {coopTheme , marcheTheme, idsmTheme, fustTheme } from "./theme.js"

// Global Context providers give us access to global state
// See https://tanstack.com/query/v4/docs/react/overview
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

// App Settings Context, provides global access to settings.js
import { SettingsContextProvider } from "./util/SettingsContext";

// Local Storage Context, provides access to local storage (for Favourites etc.)
import { LocalStorageContextProvider } from "./util/LocalStorageContext";

// Provides project-specific job filters ("attributes")
import { FiltersContextProvider } from "./util/FiltersContext";

// react-query client, this is used for caching and managing API calls
const queryClient = new QueryClient();


import "./assets/css/index.scss"
import ErrorPage from "./ErrorPage";
import JobAboPage from "./JobAboPage";
import TeaserPage from "./TeaserPage";
import settings from "./settings";
import CareerCenterPage from "./CareerCenterPage";

const container = document.getElementById("root");
const root = createRoot(container);

/* const currentSearchParams = new URLSearchParams(window.location.search);
const themeParam = currentSearchParams.get('theme'); */


/**
 * Wrapper with different React context providers around the app.
 * These mostly provide global data that is used throughout the app, typically
 */
const Wrapper = (props) => {
  const [theme, setTheme] = useState(coopTheme);

  useEffect(() => {
    const currentSearchParams = new URLSearchParams(window.location.search);
    const themeParam = currentSearchParams.get('theme');

    if (themeParam === 'marche') {
      setTheme(marcheTheme);
    } else if (themeParam === 'idms') {
      setTheme(idsmTheme);
    } else if (themeParam === "fust") {
      setTheme(fustTheme);
    }
   }, []);

  return ( 
  <ThemeProvider theme={theme}>
  <QueryClientProvider client={queryClient}>
    <SettingsContextProvider>
      <LocalStorageContextProvider>
        <FiltersContextProvider>
          <StyledEngineProvider injectFirst>
            {/* <!-- This is where the router will render the page --> */}
            <Outlet />
          </StyledEngineProvider>
        </FiltersContextProvider>
      </LocalStorageContextProvider>
    </SettingsContextProvider>
  </QueryClientProvider>
</ThemeProvider>)
}
// create a router object (react router dom v6)
const routerConfig = [
  {
    path: "/",
    element: <Wrapper />,
    errorElement: <ErrorPage />,
    //loader: <LoaderPage />,
    children: [
      { index: true, element: <CareerCenterPage /> },
      { path: "jobabo", element: <JobAboPage /> },
      { path: "teaser", element: <TeaserPage /> },
    ],
  }  
];

const router = settings.useHashRouter
  ? createHashRouter(routerConfig, { basename: settings.routerBasename })
  : createBrowserRouter(routerConfig, {
      basename: settings.routerBasename,
    });

root.render(
  <React.StrictMode>
    <RouterProvider router={router}></RouterProvider>
  </React.StrictMode>
);