/* 3 filter tabs Stellen Lehrstellen Schnupperlehre */

import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { i18n } from "../util/i18n";

const toArray = (val) =>
  typeof val === "string" ? (val.includes(",") ? val.split(",") : [val]) : [];

const JobTypes = ({
  jobType,
  filtersParams,
  setFiltersParams,
  attributes,
  filterAttributeId,
  handlePageTypeChange,
  mainFilterIds,
  grundBildungAttributes,
  ...props
}) => {
  useEffect(() => {
    if (jobType === "main") {
      //necessary to avoid a mistake on first load when there's a prefiltered URL with a filter 50 value that's not grundbildung. in this case we should not overwrite the filtersParams with the mainFilterIds but keep the existing filter 50 values.
      if(!filtersParams.f[50]){
        handleChange("main", true);
      }
     
    }
  }, []);



  if (!filterAttributeId) {
    return <div>Filter attribute ID is required</div>;
  }
  const handleChange = (value, firstLaunch) => {
    //update selected job type visually
    handlePageTypeChange(value);

    // adjust prefilters upon job type change
    let preFilterKey = null;
    if (value == "Lehrstelle") {
      preFilterKey = grundBildungAttributes.lehrstellen;
    } else if (value == "Schnupperlehre") {
      preFilterKey = grundBildungAttributes.schnupper;
    } else if (value == "main") {

      let grundBildungIds = Object.values(grundBildungAttributes)
   
      if(filtersParams.f[50]){
        //filter out grundbildungIds in case user clicked between the job type tabs
        let currentPositionIds = toArray(filtersParams.f[50])
        let filteredPositionIds = []
        currentPositionIds.forEach(id => {
         if(!grundBildungIds.includes(id)){
          filteredPositionIds.push(id)
         }
        })
        preFilterKey = filteredPositionIds.join(",").concat(mainFilterIds.join(","));
      }else{
        //show all filter 50 jobs, excluding grundbildung ids
        preFilterKey = mainFilterIds.join(",");
      }
 
    }

    let newFilterParams = {
      lang: filtersParams.lang,
      limit: filtersParams.limit,
      offset: 0,
    };

    newFilterParams.f = {...filtersParams.f}
    newFilterParams.f[50] = preFilterKey

    //exlude fachrichtung when grundbildung
    if(value !== "main"){
      delete newFilterParams.f[20] 
    }else{
      delete newFilterParams.f[370]
    }
    //console.log("NEW PARAMS: " + JSON.stringify(newFilterParams))
    setFiltersParams(newFilterParams);
  };

  return (
    <>
      <Box className="job-categories">
        <button
          onClick={() => handleChange("main")}
          className={jobType == "main" ? "main-btn job-category" : "outline-btn job-category"}
        >
          {i18n.translations.jobTypeMain}
        </button>
        <button
          onClick={() => handleChange("Lehrstelle")}
          className={jobType == "Lehrstelle" ? "main-btn job-category" : "outline-btn job-category"}
        >
          {i18n.translations.jobTypeApprenticeLabel}
        </button>
        <button
          onClick={() => handleChange("Schnupperlehre")}
          className={
            jobType == "Schnupperlehre" ? "main-btn job-category" : "outline-btn job-category"
          }
        >
          {i18n.translations.jobTypeTrialLabel}
        </button>
      </Box>
    </>
  );
};

export default JobTypes;
